import React, {Component} from 'react';
import styled from 'styled-components'
import NavTemplate from "./NavTemplate";

const bgImgPath = process.env.PUBLIC_URL + '/assets/img/move.png';

class HeaderTemplate extends Component {
  render() {
    return (
      <Container>
        <div className="overlay"/>
        <NavTemplate/>
        <h2>{this.props.title}</h2>
      </Container>
    );
  }
}


const Container = styled.div`
background: url(${bgImgPath}) center center;
background-size: cover;
height: 365px;
position: relative;
display: flex;
h2{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%)  translateY(-50%);
  font-weight: 500;
  font-size: 48px;
  text-align: center;
  color: #F2F2F2;
}
.overlay{
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background:rgba(0,0,0,0.3);
  position: absolute;
}
`

export default HeaderTemplate;
