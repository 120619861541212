import React, {Component} from 'react';
import {
  BrowserRouter,
  Route,
  Switch
} from 'react-router-dom'
import {
  createGlobalStyle,
  ThemeProvider
} from "styled-components";
import HttpsRedirect from 'react-https-redirect';
import LanguageProvider
  from './Context/LanguageProvider'
import HomeScreen from "./screens/HomeScreen";
import AboutScreen from "./screens/AboutScreen";
import FAQScreen from "./screens/FAQScreen";
import InsuranceScreen from "./screens/InsuranceScreen";
import PartnersScreen from "./screens/PartnersScreen";
import ContactScreen from "./screens/ContactScreen";
import ReservationScreen from './screens/ReservationScreen';
import './App.css';

const theme = {};

class App extends Component {
  render() {
    return (
      <HttpsRedirect>
        <ThemeProvider theme={theme}>
          <React.Fragment>
            <LanguageProvider>
              <BrowserRouter>
                <Switch>
                  <Route exact path='/'
                        component={HomeScreen}/>
                  <Route exact path='/apropos'
                        component={AboutScreen}/>
                  <Route exact path='/faq'
                        component={FAQScreen}/>
                  <Route exact path='/insurance'
                        component={InsuranceScreen}/>
                  <Route exact path='/partners'
                        component={PartnersScreen}/>
                  <Route exact path='/contact'
                        component={ContactScreen}/>
                  <Route path='/reservation/:boxIndex'
                        component={ReservationScreen}/>
                  <Route path='/reservation'
                        component={ReservationScreen}/>
                </Switch>
              </BrowserRouter>
            </LanguageProvider>
            <GlobalStyle/>
          </React.Fragment>
        </ThemeProvider>
      </HttpsRedirect>
    );
  }
}

const GlobalStyle = createGlobalStyle`
*, *:before, *:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
 font-family: 'Roboto', sans-serif;
 overflow-x: hidden;
}
html{
 overflow-x: hidden;
}
`

export default App;
