export default {
  title1: {
    fr: 'Nos chers partenaires',
    en: 'Our beloved partners',
    nl: 'Ons lieve partners',
  },
  subTitle1: {
    fr: '',
    nl: '',
    en: '',
  },
  text1:{
    fr: [
      '<a href="https://www.taux-evolution.fr">Calculez une variation en pourcentage</a>',
      '<a href="http://www.e-annuaire.net/">annuaire de site</a>',
      '<a href="http://www.annubel.com/">Annuaire Belge</a>',
      '<a href="https://www.el-annuaire.com" target="_blank" title="annuaire gratuit"><img src="https://www.el-annuaire.com/annuaire-gratuit/el-annuaire-88f.gif" width="88" height="31" border="0"></a>',
      `<a href="https://colonelreyel.fr/"><img src="https://colonelreyel.fr/banniere-150-40.png" title="Répertoire Internet Colonel" alt="Répertoire de l'Officier supérieur Internet" width="150" height="40" /></a>`,
    ],
    nl: [
      '<a href="https://www.taux-evolution.fr">Calculez une variation en pourcentage</a>',
      '<a href="http://www.e-annuaire.net/">annuaire de site</a>',
      '<a href="http://www.annubel.com/">Annuaire Belge</a>',
      '<a href="https://www.el-annuaire.com" target="_blank" title="annuaire gratuit"><img src="https://www.el-annuaire.com/annuaire-gratuit/el-annuaire-88f.gif" width="88" height="31" border="0"></a>',
      `<a href="https://colonelreyel.fr/"><img src="https://colonelreyel.fr/banniere-150-40.png" title="Répertoire Internet Colonel" alt="Répertoire de l'Officier supérieur Internet" width="150" height="40" /></a>`,
    ],
    en: [
      '<a href="https://www.taux-evolution.fr">Calculez une variation en pourcentage</a>',
      '<a href="http://www.e-annuaire.net/">annuaire de site</a>',
      '<a href="http://www.annubel.com/">Annuaire Belge</a>',
      '<a href="https://www.el-annuaire.com" target="_blank" title="annuaire gratuit"><img src="https://www.el-annuaire.com/annuaire-gratuit/el-annuaire-88f.gif" width="88" height="31" border="0"></a>',
      `<a href="https://colonelreyel.fr/"><img src="https://colonelreyel.fr/banniere-150-40.png" title="Répertoire Internet Colonel" alt="Répertoire de l'Officier supérieur Internet" width="150" height="40" /></a>`,
    ],
  }
}