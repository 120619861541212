import validators from 'validator';

/* validators with correct error codes */

export const notEmpty = (str) => {
  return validators.isEmpty(str) ? 'notEmpty' : '';
}

export const validEmail = (str) => {
  return validators.isEmail(str) ? '' : 'validEmail';
}

export const validPhone = (str) => {
  return validators.isMobilePhone(str) ? '' : 'validPhone';
}

/* Sanitizers */

export const trim = (str) => {
  return validators.trim(str);
}

export const emailSanit = (str) => {
  return validators.normalizeEmail(str);
}

