import React from 'react'
import styled from 'styled-components'

const assetsPath = process.env.PUBLIC_URL + '/assets/img';


class AboutSectionItemHome extends React.Component {
  render() {
    return (
      <Container>
        <div className={"header"}>
          <img
            src={`${assetsPath}/${this.props.img}`}
            alt={this.props.alt}/>
          <h5>{this.props.title}</h5>
        </div>
        <p>{this.props.desc}</p>
      </Container>
    )
  }
}

export default AboutSectionItemHome

const Container = styled.div`
background:white;
padding: 16px 0px 0px 16px;
.header{
display: flex;
img{
  margin-right: 16px;
  margin-top: -10px ;
  height: 38px;
  width: 38px;
}
  h5{
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    font-size: 20px;
    color: #3D3349;
  }
}
p{
  font-weight: normal;
  line-height: 34px;
  font-size: 18px;
  color: rgba(61, 51, 73, 0.85);
}
`