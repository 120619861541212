import React, { Component } from 'react'
import DatePicker from 'react-date-picker';
import styled from 'styled-components'
import {LanguageContext} from "../Context/LanguageProvider";
import getText from "../i18n/index";

export default class DateFormField  extends Component {

  static contextType = LanguageContext;

  onChange(value){
    this.props.onChange(''+value)
  }

  render(){
    return (
      <Container>
        <div className={"field"}>
          {/* Label of the field */}
          <div className={"labelWrapper"}>
            <p className={"label"}>
              {this.props.label}
            </p>
          </div>
          {/* Actual input */}
          <DatePicker
            onChange={this.onChange.bind(this)}
            value={new Date(this.props.value || '' + new Date())}
            minDate={this.props.minDate}
            />
          {/* Eventually error msg */}
          {
            this.props.error && <p className={"error"}>{getText(this.context.lang, "contact", `form.error.${this.props.error}`)}</p>
          }
        </div>
      </Container>
    )
  }
}

const Container = styled.div`
   display: flex;
   flex: 1;
   .field{
     display: flex;
     flex-direction: column;
     flex: 1;
     margin-top: 15px;
     margin-left: 15px;
     .react-date-picker__wrapper{
       min-width: 250px;
       flex: 1;
       width: 100%;
       border: none;
       border: solid 1px;
       border-color:  rgba(0, 0, 0, 0.3);
       border-radius: 5px;
       line-height: 28px;
     }
   }
   .label{
     font-weight: bold;
     color: rgba(0, 0, 0, 0.7);
     display: flex;
     margin: 0px 0px;
   }
   .labelWrapper{
     margin: 5px 0px;
   }
   .error {
     color: rgba(255, 0, 0, 0.7);
   }
}
`