import React, {Component} from 'react';
import styled from 'styled-components'
import {LanguageContext} from "../Context/LanguageProvider";
import getText from "../i18n/index";

const checkImgPath = process.env.PUBLIC_URL + '/assets/img/check.png';

class ContentFAQScreen extends Component {
  static contextType = LanguageContext;

  render() {
    const section1 = getText(this.context.lang, "faq", "text1");
    return (
      <Container>
        <div className="section1">
          <h2>{getText(this.context.lang, "faq", "title1")}</h2>
          <h3>{getText(this.context.lang, "faq", "subTitle1")}</h3>
          {section1.map(({q, a}, index) => (
            <div className={'paragraphe'} key={index}>
              <div className={'question'}>
                <img src={checkImgPath} alt="check icon"/>
                <em>{q}</em>
              </div>
              <br/>
              {a}
            </div>
          ))}
        </div>
      </Container>
    );
  }
}


const Container = styled.div`
  .section1,.section2{
     h2{
      font-weight: 900;
      font-size: 24px;
      color: rgba(255, 56, 46, 0.7);
      margin-bottom: 24px;
    }
    h3{
      font-weight: bold;
      line-height: 21px;
      font-size: 15px;
      color: rgba(0, 0, 0, 0.7);
      margin-bottom: 24px;
    }
   .paragraphe{
      display: flex;
      flex-direction: column;
      margin-bottom: 48px;
      color: rgba(0, 0, 0, 0.7);
      .question {
        display: flex;
        flex-direction: row;
        flex: 1;
      }
      img {
          align-self: flex-start;
          margin-top: 4px;
          margin-right: 16px;
      }
  }
}
`

export default ContentFAQScreen;
