import React, { Component } from 'react'
import getText from "../i18n/index"
import {LanguageContext} from "../Context/LanguageProvider";


export default class LocationSidePanelSelector extends Component {

  static contextType = LanguageContext;

  render(){
    return (
      <React.Fragment>
        { this.props.groundPrice !== 0 && (
            <div onClick={(ev) => this.props.onFloorSelection(true, ev)} className={"floorSelector"} style={this.props.getFloorStyle(this.props.form.groundFloor)}>
              <h3>{getText(this.context.lang, "reservation", "groundFloorSelection")}</h3>
              <p>{'€' + this.props.groundPrice.toFixed(2) + '/mois '} <span className={'priceDetail'}>{'HTVA'}</span></p>
              <p>{'€' + (this.props.groundPrice.toFixed(2) * 1.21).toFixed(2) + '/mois '} <span className={'priceDetail'}>{'TVAC'}</span></p>
            </div>
          )
        }
        {
          this.props.priceUpstairs !== 0 && (
            <div onClick={(ev) => this.props.onFloorSelection(false, ev)} className={"floorSelector"} style={this.props.getFloorStyle(!this.props.form.groundFloor) }>
              <h3>{getText(this.context.lang, "reservation", "upFloorSelection")}</h3>
              <p>{'€' + this.props.priceUpstairs.toFixed(2) + '/mois '} <span className={'priceDetail'}>{'HTVA'}</span></p>
              <p>{'€' + (this.props.priceUpstairs.toFixed(2) * 1.21).toFixed(2) + '/mois '} <span className={'priceDetail'}>{'TVAC'}</span></p>
            </div>
          )
        }
      </React.Fragment>
    )
  }
}
