import React, { Component } from 'react'
import styled from 'styled-components'
import {LanguageContext} from "../Context/LanguageProvider";
import getText from "../i18n/index";

class ReservationBoxCard extends Component {

  static contextType = LanguageContext;

  render(){
    const HTVA = parseFloat(this.props.box.price) === 0 ? parseFloat(this.props.box.priceUpstairs) : parseFloat(this.props.box.price)
    const TVAC = HTVA * 1.21
    return (
      <Container>
          <div className={'hero'}>
            <p>{this.props.box.area + " m²"}</p>
          </div>
          <div className={"pic"}>
              <img src={this.props.box.pic} alt={"price"} className={'actualPic'} onClick={this.props.onImgClick} />
          </div>
          <div className={'desc'}>
            <p>{ this.props.box["description"+this.context.lang.toUpperCase()] }</p>
          </div>
          <div className={'price'}>
            <div className={'prices'}>
              <p>{'€' + HTVA.toFixed(2) + '/mois '} <span className={'priceDetail'}>{'HTVA'}</span></p>
              <p>{'€' + TVAC.toFixed(2) + '/mois '} <span className={'priceDetail'}>{'TVAC'}</span></p>
            </div>
            <div className={'button-res'} onClick={this.props.onReserve}> 
              {getText(this.context.lang, 'reservation', 'reserve')}
            </div>
          </div>
      </Container>
    )
  }
}


const Container = styled.div` 
  flex-direction: row;
  margin-top: 40px;
  flex: 1;
  display: flex;
  height: 150;
  box-shadow: 5px 0px 22px rgba(0, 0, 0, 0.05);
  border-radius: 2;
  height:150px;
  @media (max-width: 500px) {
    flex-direction: column;
    height: auto;
    align-items: center;
  }
  .hero {
    display: flex;
    flex: 1;
    background-color: #FF382EB5;
    height: 150px;
    @media (max-width: 500px) {
      height: 90px;
      width: 100%;
    }
    align-items: center;
    justify-content: center;
    p {
      font-weight: bold;
      color: #ffffff;
    }
  }
  .pic {
    display: flex;
    flex: 1;
    height: 150px;
    max-width: 200px;
  }
  .actualPic{
    max-width:100%;
    height:auto;
    object-fit: contain;
  }
  .desc {
    display: flex;
    flex: 1;
    /* height: 150px; */
    align-items: center;
    p {
      font-size: 12px;
      font-family: 'Roboto';
      .priceDetail{
        color: #ff0000;
      }
    }
  }
  .price {
    display: flex;
    flex: 1;
    height: 150px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    @media (max-width: 500px) {
      flex-wrap: wrap;
    }
    .prices{
      display: flex;
      flex: 4;
      font-weight: 900;
      font-size: 20;
      color: '#3D3349';
      flex-direction: column;
      p {
        display: inline-flex;
        justify-content: flex-end;
        @media (max-width: 500px) {
          justify-content: center;
        }
      }
    }
  }
  .button-res{
    margin: 15px;
    display: flex;
    flex: 1;
    font-size: 16px;
    background:none;
    border: 1px solid #FF382E;
    border-radius: 4px;
    color: #FF382E;;
    padding: 10px;
    font-weight: bold;
    max-width: 200px;
    align-items: center;
    justify-content: center;
   }
`

export default ReservationBoxCard;