import React from 'react'
import styled from 'styled-components'
import getText from "../i18n";
import {LanguageContext} from "../Context/LanguageProvider";

const squarePoints = process.env.PUBLIC_URL + '/assets/img/squarePointStorage.png';
const checkImgPath = process.env.PUBLIC_URL + '/assets/img/check.png';

export default class ContactSectionHome extends React.Component {
  static contextType = LanguageContext;

  render() {
    const listOpening = getText(this.context.lang, "home", "contactSection.list1")
    const listMss = getText(this.context.lang, "home", "contactSection.list2")

    return (
      <Container className="Contact contactSection">
          <div className="container">
            <div className="row">
              <div className="horaires col-md-5">
                <h2>{getText(this.context.lang, "home", "contactSection.title")}</h2>
                <h5>{getText(this.context.lang, "home", "contactSection.subTitle1")}</h5>
                {listOpening.map((text,index) =>
                  <div key={index} className={'textWrapper'}>
                    <img className={'check'} src={checkImgPath} alt="check icon"/><p>{text}</p>
                  </div>
                  )}
                <h5>{getText(this.context.lang, "home", "contactSection.subTitle2")}</h5>
                {listMss.map((text,index) =>
                  <div key={index} className={'textWrapper'}>
                    <img className={'check'} src={checkImgPath} alt="check icon"/><p>{text}</p>
                  </div>
                )}
              </div>
              <div className="map col-md-7">
                
                <iframe
                  title={'google map'}
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2519.0815141752823!2d4.310027515745862!3d50.848174779532265!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3c400ed57ab85%3A0x1d3b2dd432542df6!2sChauss%C3%A9e+de+Ninove+357%2C+1070+Anderlecht!5e0!3m2!1sen!2sbe!4v1543496795179"/>
                <img className={'squarePoints'} src={squarePoints} alt={'square points'}/>
              </div>
            </div>
          </div>
      </Container>
    )
  }
}

const Container = styled.div`
position: relative;
z-index: 0;
.container{
padding-top: 128px;
  padding-bottom: 128px;
}
.map{
  position: relative;
  z-index: 0;
}

iframe{
width: 100%;
height: 100%;
border: none;
min-height: 500px;
box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.1);
}

img{
margin-right: 8px;
align-self: flex-start;
margin-top: 6px;
}
.squarePoints{
position: absolute;
  z-index: -10;
  bottom: -50px;
  right: -50px;
  display: none;
}
h2{
font-weight: 900;
    line-height: normal;
    font-size: 50px;
    text-align: left;
    color: #FF4238;
}
h5{
  font-size: 20px;
    color: #3D3349;
    font-weight: 700;
    margin-bottom: 16px;
}

.textWrapper{
 display: flex;
}
p{
font-style: normal;
font-weight: normal;
line-height: 25px;
max-width: 250px;
font-size: 18px;
color: #3D3349;
}

@media  (min-width: 768px){
.squarePoints{
  display: block;
}
}

@media  (min-width: 997px){
.bg{
  position: absolute;
  height: 400px;
  width: 80%;
  background:#463C51;
  z-index: -10;
  left: -15px;
  top: -40px;
},
}
`