export default {
  headerTitle: {
    en: 'Insurance',
    fr: 'Assurances',
    nl: 'Verzekering'
  },
  title1: {
    en: "MMS's Insurance",
    fr: 'Assurances chez MSS',
    nl: 'Verzekering aan MSS'
  },
  subTitle1: {
    en: '',
    fr: '',
    nl: ''
  },
  text1: {
    en: [
      'We know your goods are valuable. For this reason, we make all the best to ensure the security of your goods and we make our best to ensure correctly our warehouse.',
      'You have to possibilities to insure your goods :',
      'You ask your insurance broker to extend your personal insurance for your goods to us. In that case please provide us an insurane certificate',
      'Or we insure your goods for you. In that case you are covered for a value of 2500€ for a 10€ monthly prime or for a value of 5 000€ for a mothly prime of 10€. Please consult us for other values',
      'If, despite everything, you do not wish to insure yourself at all, we will ask you to sign a waiver of recourse, i.e. you waive any recourse against My Self Storage in case anything happens to your property and you are deemed to be the own insurer of your property.',
    ],
    nl: [
      'We weten dat uw spullen waardevol zijn. Voor deze redenen doen we, alles wat we kunnen om de veiligheid van uw goederen te garanderen',
      'U heeft 2 mogelijkheden wat betreft de verzekering van uw goederen',
      'U vraagt aan uw verzekering makelaar om uw spullen te verzekeren op basis van uw eigen verzekering. In dit geval geeft u ons een verzekering ceificaat van uw makelaar voor de goederen in opslag bij',
      'Ofwel verzekeren we uw goederen  voor u. In dit geval kiest u voor een waarde van 2500€ voor een maandelijkse premie van 10€ of een waarde van 5000 € voor een premie van 15€ (raadpleeg ons voor andere bedragen te verzekeren)',
      'Indien u zich ondanks alles niet wenst te verzekeren, zullen wij u vragen om een verklaring van afstand van verhaal te ondertekenen, d.w.z. dat u afziet van elk verhaal tegen Mijn Opslagruimte in het geval er iets met uw eigendom gebeurt en u wordt beschouwd als de eigen verzekeraar van uw eigendom.',
    ],
    fr: [
      'Nous savons que vos biens sont précieux. C’est pour ca que, nous faisons tout ce qui est en notre pouvoir pour assurer leur sécurité  et nous veillons à ce  que  notre centre de stockage soit   pleinement  assuré.',
      'Vous avez 2 possibilités en matière d’assurance de vos biens :',
      'Soit vous demandez à votre courtier d’assurance d’assurer ceux-ci sur base de votre assurance personnelle. Dans ce cas, nous vous demandons de nous fournir un certificat d’assurance pour vos biens dans notre centre',
      'Soit nous assurons vos bien pour vous. Dans ce cas, vous êtes couverts soit pour une valeur de 2500 € pour une prime mensuelle de 10€ ou pour une valeur de 5 000 € pour une prime mensuelle de 15€ (nous consulter pour d’autres valeurs)',
      'si malgré tout, vous ne désirez pas vous assurer du tout, nous vous demanderons de signer un abandon de recours, c’est-à-dire que vous abandonnez tout recours contre My Self Storage au cas où il arrive quoi que ce soit à vos bien et vous êtres réputés être le propre assureur de vos biens.',
    ],
  }
}