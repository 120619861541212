import React from 'react'
import styled from 'styled-components'
import {LanguageContext} from "../Context/LanguageProvider";
import LanguageSelector from "./LanguageSelector";

const logoPath = process.env.PUBLIC_URL + '/assets/img/logo.svg';
const menuPath = process.env.PUBLIC_URL + '/assets/img/menu.svg';

class MobileNav extends React.Component {
  static contextType = LanguageContext;

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      value: "fr"
    };

  }

  toggleMobileMenu() {
    this.setState({isOpen: !this.state.isOpen})
  }

  render() {
    return (
      <Nav className={'nav-mobile'}>
        <div className="nav-mobile-container">
          <div className={'header'}>
            <div className="logo-wrapper">
              <div>
                <img className={"logo"}
                     src={logoPath}
                     alt="company logo"/>
                <LanguageSelector/>
              </div>
              <div className="select-dropdown">
              </div>
            </div>
            <div
              onClick={this.toggleMobileMenu.bind(this)}>
              <img className={"menuIcon"}
                   src={menuPath} alt=""/>
            </div>
          </div>
          <div
            className={'container-cs ' + (this.state.isOpen ? '' : 'd-none')}>
            <ul>
              {this.props.children}
            </ul>
            <button
              onClick={this.toggleMobileMenu.bind(this)}
              className={"button-close " + (this.state.isOpen ? '' : 'd-none')}>
              <span
                className={"text-white"}>X</span>
            </button>
          </div>
        </div>
      </Nav>
    )
  }
}

export default MobileNav

const Nav = styled.nav`
.nav-mobile-container{
background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, 
rgba(0, 0, 0, 0) 100%);
width: 100%;
position: fixed;
display: flex;
justify-content: space-between;
z-index: 10;
.header{
display: flex;
width: 100%;
padding: 16px;
justify-content: space-between;
.logo-wrapper{
display: flex;
.logo{
height: 36px;
}
}
select{
background:none;
color:white;
margin-left: 8px;
option{
  color:black;
  background:none;
}
}
.menuIcon{
margin-top: 8px;
height: 24px;
}
}
.container-cs{
padding: 16px;
background:rgba(0,0,0,0.9);
width: 100%;
display: flex;
justify-content: space-between;
position: absolute;
z-index: 10;
ul{
left: 0;
display: flex;
flex-direction: column;
list-style: none;
li{
margin-left: 16px;
margin-bottom: 8px;
span{
font-size:20px ;
font-weight: 500;
color:white;
text-decoration: none;
text-shadow: 0px 0.5px 0.5px rgba(0, 0, 0, 0.8);
&:visited{
  color:white;
}
&:hover{
  color:white;
}
}
button{
 font-weight: 600;
 padding: 8px;
 margin-top: 10px;
 color: white;
 background: rgba(255, 255, 255, 0.1);
 border: 1px solid #FFFFFF;
 border-radius: 10px;
}
}
}
.button-close {
border-radius: 100px;
border: none;
height: 40px;
width: 40px;
background:rgba(255,255,255,0.5);
margin-right: 16px;
span{
font-size: 16px;
font-weight: 500;
} 
}
}
}
`