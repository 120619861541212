import React, {Component} from 'react'
import {LanguageContext} from "../Context/LanguageProvider"
import TemplateScreen from "./TemplateScreen"
import ContentContactScreen
  from "../components/ContentContactScreen"

class ContactScreen extends Component {
  static contextType = LanguageContext

  render() {
    const title = ""
    return (
      <TemplateScreen
        title={title}
        content={<ContentContactScreen/>}/>
    );
  }
}

export default ContactScreen;