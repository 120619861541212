export default {
  reserve: {
    fr: 'Réserver',
    nl: 'Reserveren',
    en: 'Book',
  },
  form: {
    title: {
      en: 'Box reservation',
      fr: 'Réservation de boxes',
      nl: 'Boxes reservatie',
    },
    description: {
      en: "",
      fr: "",
      nl: "",
    },
    fields: {
      floor: {
        en: 'Please select floor',
        nl: 'Selecteer een verdiep',
        fr: 'Sélectionner un étage'
      },
      name: {
        en: 'Name',
        fr: 'Nom',
        nl: 'Naam',
      },
      firstName: {
        en: 'First Name',
        fr: 'Prénom',
        nl: 'Voornaam',
      },
      email: {
        en: 'Email',
        fr: 'Email',
        nl: 'Email',
      },
      phone: {
        en: 'Phone',
        fr: 'Téléphone',
        nl: 'Telefoon',
      },
      dateStart: {
        en: 'Start Date',
        fr: 'Date de début',
        nl: 'Begin Datum',
      },
      dateEnd: {
        en: 'End Date',
        fr: 'Date de fin',
        nl: 'Einde Datum',
      },
      length: {
        en: 'Length',
        fr: 'Durée',
        nl: 'lengte',
      },
      message: {
        en: 'Message',
        fr: 'Message',
        nl: 'Bericht',
      },
    },
    error: {
      notEmpty: {
        fr: 'Ce champ ne peut pas être vide',
        en: 'This field cannot be empty',
        nl: 'Dit veld mag niet leeg zijn'
      },
      validEmail: {
        fr: 'Veuillez entrer une adresse mail valide',
        en: 'Please enter a valid email address',
        nl: 'Vul een geldig e-mailadres in'
      },
      validPhone: {
        fr: 'Veuillez entrer un numéro de téléphone valide',
        en: 'Please enter a valid phone number',
        nl: 'Vul een geldig telefoon nummer in',
      },
    },
    submit: {
      fr: 'Envoyer',
      nl: 'Zenden',
      en: 'Submit',
    },
    submitSuccess: {
      fr: 'Merci pour votre message!',
      nl: 'Bedankt voor uw bericht!',
      en: 'Thank you for your message!'
    },
    submitError: {
      fr: 'Oops il y a eu un problème...',
      nl: 'Oeps er was een probleem...',
      en: 'Oops there was a problem...',
    }
  },
  groundFloorSelection: {
    fr: 'Prix rez de chaussée',
    nl: 'Prijs begane grond',
    en: 'Price ground floor'
  },
  upFloorSelection: {
    fr: 'Prix 1er étage',
    nl: 'Prijs 1e verdieping',
    en: 'Price 1st floor'
  }
}