import React, { Component } from 'react'
import FormField from './FormField';
import { SideSheet } from 'evergreen-ui'
import styled from 'styled-components'
import {LanguageContext} from "../Context/LanguageProvider";
import getText from "../i18n/index";
import DateFormField from "../components/DateFormField"
import { Redirect } from 'react-router'
import Loader from 'react-loader-spinner'

import ReCAPTCHA from "react-google-recaptcha";
import * as config from '../config'
import LocationPanelHero from './LocationPanelHero';
import LocationPanelStairSelector from "./LocationSidePanelStairSelector"

export default class LocationSidePanel extends Component {

  static contextType = LanguageContext;

  getFloorStyle(selected){
    return {
      opacity: !selected ? 0.6 : 1,
      borderColor: selected ? 'rgba(61, 139, 0, 0.8)' : 'black',
    }
  }

  onFloorSelection(val, ev){
    ev.preventDefault()
    this.props.updateFloor(val)
  }

  onImgClick(uri, ev){
    ev.preventDefault()
    this.props.showModal(uri)
  }

  render(){
    const actBox = this.props.boxes && this.props.boxes.length && this.props.selected > -1 ? this.props.boxes[this.props.selected] : {}
    const priceUpstairs = actBox.priceUpstairs ? parseFloat(actBox.priceUpstairs) : 0
    const groundPrice = actBox.price ? parseFloat(actBox.price) : 0
    return (
      <SideSheet
        isShown={this.props.show}
        onCloseComplete={this.props.close}
        onOpenComplete={() => {
          window.scrollTo(0, 0)
        }}
        className={"test"}
        width={Math.min(window.innerWidth, 900)}
      >
        <Container id={"container"}>
          <div className="section1">
            <h2>{getText(this.context.lang, "reservation", "form.title")}</h2>
            <h3>{getText(this.context.lang, "reservation", "form.description")}</h3>
            <LocationPanelHero onImgClick={this.onImgClick.bind(this, actBox.pic)} box={actBox} />
            <h6>{getText(this.context.lang, "reservation", "form.fields.floor")}</h6>
            <div className={"formRow"}>
              <LocationPanelStairSelector 
                form={this.props.form}
                onFloorSelection={this.onFloorSelection.bind(this)}
                getFloorStyle={this.getFloorStyle.bind(this)}
                groundPrice={groundPrice}
                priceUpstairs={priceUpstairs}
                />
            </div>
            <div className={"formRow"}>
              <FormField label={getText(this.context.lang, 'reservation', 'form.fields.name')}
                            value={this.props.form.name.value}
                            placeholder={"Dupont"} 
                            error={this.props.form.name.error}
                            onChange={(ev) => this.props.onFieldChange('name', ev)}/>
              <FormField label={getText(this.context.lang, 'reservation', 'form.fields.firstName')}
                            value={this.props.form.firstName.value}
                            placeholder={"Jean"} 
                            error={this.props.form.firstName.error}
                            onChange={(ev) => this.props.onFieldChange('firstName', ev)}/>      
            </div>
            <div className={"formRow"}>
              <FormField label={getText(this.context.lang, 'reservation', 'form.fields.email')}
                            value={this.props.form.email.value}
                            placeholder={"jean.dupont@gmail.com"}
                            error={this.props.form.email.error} 
                            onChange={(ev) => this.props.onFieldChange('email', ev)}/>
              <FormField label={getText(this.context.lang, 'reservation', 'form.fields.phone')}
                            value={this.props.form.phone.value}
                            placeholder={"047xxxxxxx"}
                            error={this.props.form.phone.error}
                            onChange={(ev) => this.props.onFieldChange('phone', ev)}/>
            </div>
            <div className={"formRow"}>
              <DateFormField label={getText(this.context.lang, 'reservation', 'form.fields.dateStart')}
                              value={this.props.form.dateStart}
                              placeholder={getText(this.context.lang, 'reservation', 'form.fields.dateStart')}
                              error={this.props.form.dateStart.error}
                              onChange={(ev) => this.props.onFieldChange('dateStart', ev, true)}
                              minDate={new Date()}/>
          
              <DateFormField label={getText(this.context.lang, 'reservation', 'form.fields.dateEnd')}
                              value={this.props.form.dateEnd}
                              placeholder={getText(this.context.lang, 'reservation', 'form.fields.dateEnd')} 
                              error={this.props.form.dateEnd.error}
                              onChange={(ev) => this.props.onFieldChange('dateEnd', ev, true)}
                              minDate={new Date(this.props.form.dateStart.value || ''+new Date())}/>
            </div>
            <FormField label={getText(this.context.lang, 'reservation', 'form.fields.message')}
                            value={this.props.form.message.value}
                            placeholder={getText(this.context.lang, 'reservation', 'form.fields.message')}
                            multiline 
                            error={this.props.form.message.error}
                            onChange={(ev) => this.props.onFieldChange('message', ev)}/>
            <div className={"recaptchaWrapper"}>
              <ReCAPTCHA
                sitekey={config.repcaptchaSiteKey}
                onChange={this.props.onCaptchaChange}
              />
            </div>
            <p style={{color: 'rgba(255, 0, 0, 0.7)'}}>
              {this.props.form.captcha.error && getText(this.context.lang, "contact", `form.error.${this.props.form.captcha.error}`)}
            </p>
            
            {/* buttons */}

            <div className={"formRow"}>
              {
              
              this.props.submitting.loading ? (
                <Loader 
                  type="Oval"
                  color="#FF382E"
                  height="50"	
                  width="50"
                />   
                ) : (
                  <button onClick={this.props.onSubmit} 
                      className={"button-submit"}>
                        {getText(this.context.lang, 'contact', 'form.submit')}
                  </button>
                )
              }

              <button onClick={this.props.onCancel} 
                  className={"button-submit"}>
                  {getText(this.context.lang, 'contact', 'form.cancel')}
              </button>
            </div>
            
            {/* redirection */}

            {
              this.props.submitting.success && <Redirect to='/'/>
            }
          </div>
        </Container>
      </SideSheet>
    )
  }
}

const Container = styled.div`
  margin: 30px;
  max-width: 600px;
  @media (max-width: 500px) {
    width: 320px;
    margin: 0px;
  }
  .priceDetail {
    font-size: 12px;
    font-family: 'Roboto';
  }
  .floorSelector{
    margin-left: 15px;
    margin-top: 15px;
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: rgba(61, 51, 73, 0.03);
    border-radius: 4px;
    border: 2px solid;
    align-items: center;
    justify-content: space-around;
    p{
      margin-bottom: 0;
    }
  }
  .section1,.section2{
    display: flex;
    flex-direction: column;
     h2{
      font-weight: 900;
      font-size: 48px;
      color: rgba(255, 56, 46, 0.7);
      margin-bottom: 24px;
    }
    h3{
      font-weight: bold;
      line-height: 21px;
      font-size: 15px;
      color: rgba(0, 0, 0, 0.7);
      margin-bottom: 24px;
    }
   .formRow{
     display: flex;
     flex: 1;
     flex-direction: row;
     flex-wrap: wrap;
   }
   .button-submit{
    font-size: 18px;
    background:none;
    border: 1px solid #FF382E;
    border-radius: 4px;
    color: #FF382E;;
    padding: 10px;
    font-weight: bold;
    margin-top: 32px;
    margin-left: 12px;
    max-width: 200px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
   }
   .recaptchaWrapper{
     margin-left: 15px;
     margin-top: 15px;
     @media screen and (max-height: 575px){
     iframe {
        transform:scale(0.77);
        -webkit-transform:scale(0.77);
        transform-origin:0 0;
        -webkit-transform-origin:0 0;
     }
   }
  }
}
`

