import React, { Component } from 'react'
import styled from 'styled-components'
import {LanguageContext} from "../Context/LanguageProvider";
import getText from "../i18n/index";

export default class FormField extends Component {
  static contextType = LanguageContext;

  render(){
    return (
      <Container>
        <div className={"field"}>
          {/* Label of the field */}
          <div className={"labelWrapper"}>
            <p className={"label"}>
              {this.props.label}
            </p>
          </div>
          {/* Actual input */}
          {
            this.props.multiline ? (
              <textarea type="text" 
                        value={this.props.value} 
                        placeholder={this.props.placeholder}
                        onChange={this.props.onChange} />
            ) : (
              <input type="text" 
                    value={this.props.value} 
                    placeholder={this.props.placeholder}
                    onChange={this.props.onChange} />
            )
          }
          {/* Eventually error msg */}
          {
            this.props.error && <p className={"error"}>{getText(this.context.lang, "contact", `form.error.${this.props.error}`)}</p>
          }
        </div>
      </Container>
    )
  }
}

FormField.defaultProps = {
}

const Container = styled.div`
   display: flex;
   flex: 1;
   .field{
     display: flex;
     flex-direction: column;
     flex: 1;
     margin-top: 15px;
     margin-left: 15px;
     input, textarea{
       min-width: 250px;
       flex: 1;
       width: 100%;
       border: none;
       border: solid 1px;
       border-color:  rgba(0, 0, 0, 0.3);
       border-radius: 5px;
       line-height: 28px;
     }
     textarea{
       height: 400px;
     }
     input {
      max-height: 30px;
     }
   }
   .label{
     font-weight: bold;
     color: rgba(0, 0, 0, 0.7);
     display: flex;
     margin: 0px 0px;
   }
   .labelWrapper{
     margin: 5px 0px;
   }
   .error {
     color: rgba(255, 0, 0, 0.7);
   }
}
`