export default {
    title1: {
      en: 'FAQ',
      fr: 'FAQ',
      nl: 'FAQ',
    },
    text1: {
      fr: [
        {q: 'Accès à mon box : quand et à quelles conditions ?', a: 'Vous accédez à votre box comme vous le voulez, 7 jours sur 7, grâce à votre badge personnalisé, de 6 à 23h, 365 jours par an. Vous garez votre véhicule dans notre parking intérieur sécuridé et accèdez à votre box. Des chariotys de manutention sont à votre disposition. Si votre box est situé à l’étage, vous y accéde par 2 grands monte charge', },
        {q: 'Comment mon box est-il sécurisé ?', a: 'Des caméras et systèmes d’alarme dernier cri sécurisent l’intérieur et l’extérieur du site, 24/24 et 365 jours par an. My Self Storage est relié à une centrale d’alarme qui envoie une patrouille à la moindre alerte. Notre personnel sur place fait des rondes régulières durant les heures de bureau', },
        {q: 'Et si j’ai choisi un box trop petit ? ou trop grand ?', a: 'Pas de problème. Nous vous en proposons un à la taille idéale et vous ne payez que la taille réellement choisie', },
        {q: 'Comment terminer ma location ?', a: 'C’est très simple. Il n’y a pas de durée fixe de nos contrats d’entreposage. Vous pouvez stocker chez nous dès 7 jours. Vous voulez terminer votre contrat ? Faites le nous savoir par simple mail à info@myselfstorage.biz et la location se termine dans les 15 jours suivants.', },
        {q: 'Il y a des choses que je voudrais transporter mais qui ne rentrent pas dans ma voiture', a: 'Appelez nous au 02/*647.22.31 ou au 0479/08.67.56 ou envoyez nous un mail à info@myselfstorage.biz et nous vous proposons un transport au meilleur prix', },
        {q: 'Je n’ai pas le temps pour tout emballer, que faire ?', a: 'Appelez nous au 02/*647.22.31 ou au 0479/08.67.56 ou envoyez nous un mail à info@myselfstorage.biz et nous vous enverrons une équipe d’emballeurs spécialisés', },
        {q: 'Vous proposez un selfstockage à un prix abordable à Bruxelles. Qu’est ce que cela veut dire ? Etes vous les moins chers ?', a: 'Nous avons l’ambition de proposer le meilleur rapport qualité prix à Bruxelles. En matière de self stockage, on trouve différentes offres en fonction du niveau de sécurité, du chauffage, des horaires d’accès etc etc. Nous avons analysé tout cela pour vous proposer la meilleure offre possible. Contactez nous pour en savoir plus au 02/*647.22.31 ou au 0479/08.67.56 ou envoyez nous un mail à info@myselfstorage.biz. Chez My Self Storage, vous avez directement contact avec la Direction qui vous proposera la meilleure soltion possible', },
        {q: 'Vos promotions sont-elles valables dès 7 jours de location ?',  a: 'o	Nos promostions sont valables à partir d’un mois de lcoation minimum. D’autres durées sont prévues en fonction de chaque offre promotionelle. Consultez nous au 02/*647.22.31 ou au 0479/08.67.56 ou envoyez nous un mail à info@myselfstorage.biz', },
      ],
      en: [
        {q: 'Access to my box : when and under which conditions ?', a: 'You access your box 365 days a year, thanks to your personal access badge, from 6 to 23h. You can park your vehicle in our inside secure parking and access to your box. Handling material and transpalets aare at your disposal for free. If your box is upsatairs, we have 2 big lifts at your disposal'},
        {q: 'How is my box secured ?', a: 'CCTV and up to date alarm systems secure inside and outside of the building 24/24 and 365 days a year. My Self Storage est on line with a control room that monitor our systems 24/24. In case of emergency they send a patrol on site. Our people is also controlling the space during the office hours'},
        {q: 'And if I choose a too little box ? Or too big ?', a: 'No problem. We propose you another one suiting your need and you only pay thone with the chosen dimensions',},
        {q: 'How can I stop my contract ?', a: ' It’s really simple. There is no fix time to our contracts. You can store with us as from one week. If you want to stop your contract, just send us an email to info@myselfstorage.biz and your contrcat is stopped within 15 days.',},
        {q: 'There are some goods I would like to move but they don’t enter in my car', a: 'Call us to 02/647.22.31 or 0479/08.67.56 or send us a mail to info@myselfstorage.biz and we will propose you a transport at the best conditions',},
        {q: 'I don’t have time to pack everything, what can I do ?', a: 'Call us to 02/647.22.31 or 0479/08.67.56 or send us a mail to info@myselfstorage.biz and we will send you a dedicated packer team',},
        {q: 'You propose a selfstorage at an affordable price in Brussesl. What do you mean ? are you the cheapest ?', a: 'we have the ambition to propose the best quality price in Brussels. Concerning the self storage, you will find different offers on the market in function of the security, warming, access hours etc etc. We analyzed all of this for you to propose you the best deal. Call us to 02/647.22.31 or 0479/08.67.56 or send us a mail to info@myselfstorage.biz. At My Self Storage you have a direct contact with the management of the company who will propose you the best solutions',},
        {q: 'Are your promotion available as from 7 days contract ?', a: 'our promotions are available as from 1 month contracduration. Other duration timings are in fucntion of each promotion Call us to 02/647.22.31 or 0479/08.67.56 or send us a mail to to info@myselfstorage.biz',},
      ],
      nl: [
        {q: 'Toegang tot mijn box : wanneer en aan welke condities ?', a: 'U heeft toegang tot uw box wanneer u wenst, 7 dagen op 7, dankzij uw personelijke badge, van 6 tot 23 uur, 365 dagen per jaar. U parkeert uw wagen of camion in onze bewaakte binnen parking en krijgt toegang tot uw box. Karretjes en palettruck zijn gratis beschikbaar . Als uw box in de verdiepen zit stellen wij u voor 2 grote liften',},
        {q: 'Hoe is mijn box beveiligd ?', a: 'Cameras en anti diefstal systemen beveiligen zowel binnen als buiten onze gebouw, 24/24 en 365 dagen per jaar. My Self Storage is verbonden aan een alarm centrale met interventie mogelijk 24/24. Onze personneel ter plaats bewaakt uw box ook gedurende de kantoren uren',},
        {q: 'Wat gebeeurt als ik een te groot of te klein box gekozen heb ?', a: 'Geen probleem : we stellen u voor een ander box aan de ideale maat en u betaalt alleen maar de gekoze maat',},
        {q: 'Hoe kan ik mijn verhuur stopzetten?', a: 'Het is zeel simpel. Er is geen vaste huurtermijn in onze contract. U mag bij ons vanaf 7 dagen termijn huren. U wenst uw contract stop te zeten ? Het is zeer simpel : stuur u ons een mail naar info@myselfstorage.biz en het verhuur stopt binnen de 15 volgende dagen',},
        {q: 'Er zijn spullen dat ik zou graag by My Self Storage brengen maar die niet in mijn wagen kunnen', a: 'o	Bel ons op 02/647.22.31 of 0479/08.67.56 of stuur ons een mail naar info@myselfstorage.biz en we stellen u voor een transport aan onze beste prijs',},
        {q: 'Ik heb geen tijd om alles te verpakken. Wat kan ik doen ?', a: 'Bel ons op 02/647.22.31 of 0479/08.67.56 of stuur ons een mail naar info@myselfstorage.biz en we zenden u onze ervaren verpakkers',},
        {q: 'U stelt voor een self storage aan de beste prijs in Brussel. Wat wil dat zeggen ? Zijn jullie de goedkoopste in Brussel ?', a: 'We hebben de ambitie om u de beste prijs/kwaliteit te voorstellen. Wat betreft self storage vindt u verschillende mogelijkheden in funktie van de veiligheid, verwarming, uurroosters, toegang enz enz. We hebben dit allemaal nagekeken om u de beste voorstel te maken. Voor meer informatie bel ons op 02/647.22.31 of 0479/08.67.56 of stuur ons een mail naar info@myselfstorage.biz. Bij  My Self Storage, bent u direct in contact met de Directie om de beste voorstel te krijgen',},
        {q: 'Zijn uw promoties geldig vanaf 7 dagen verhuur ? ', a: 'Onze promoties zijn geldig vanaf een maand verhuur minimum. Andere duur zijn voorzien in funktie van elke offerte. Voor meer informatie bel ons op 02/*647.22.31 of 0479/08.67.56 of stuur ons een mail naar info@myselfstorage.biz',},
      ],
    },
    subTitle1: {
      fr: 'Foire aux question',
      en: 'Frequently asked questions',
      nl: 'Veel gestelde vragen',
    }
}