import React from 'react'
import styled from 'styled-components'
import getText from "../i18n";
import {LanguageContext} from "../Context/LanguageProvider";
import $ from "jquery";

const arrowPath = process.env.PUBLIC_URL + '/assets/img/Arrow.png';

export default class HeaderBoxHome extends React.Component {
  static contextType = LanguageContext;

  scroll(ev, section, offset = 0) {
    ev.preventDefault()
    var page = $(`.${section}`)
    var speed = 750
    $('html, body').animate({scrollTop: $(page).offset().top + offset}, speed)
  }

  render() {
    return (
      <Container>
        <div className="content">
          <p>{getText(this.context.lang, "home", "header.text1")}</p>
          <button
            onClick={(ev) => this.scroll(ev, 'locationSection', 0)}>
            {getText(this.context.lang, "home", "header.buttonLocation")}
            <img src={arrowPath}
                 alt="arrow icon"/>
          </button>
        </div>
      </Container>
    )
  }
}

const Container = styled.div`
position: absolute;
width: 90%;
height: 50%;
display: flex;
flex-direction: column;
min-height: 260px;
max-width: 400px;
font-size: 32px;
font-weight: 100;
color: white;
text-align: left;
top: 45%;
justify-content: center;
padding-left: 24px;
transform: translateY(-50%);
button{
align-self:flex-start;
font-size: 18px;
background:rgba(255,255,255,0.10);
border: 1px solid #FFFFFF;
box-sizing: border-box;
border-radius: 4px;
color: #FFFFFF;
padding: 10px 16px 8px 16px;
text-transform:uppercase;
img{
margin-left: 16px;
}
}
.bold{
font-weight: bold;
}
@media (min-width: 992px) { 
font-size: 64px;
max-width: 720px;
height: 60%;
min-height: 400px;
padding: 64px;
p{
line-height: 75px;
}
}
`