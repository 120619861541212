import React from 'react'
import styled from 'styled-components'
import {LanguageContext} from "../Context/LanguageProvider";
import getText from "../i18n";
import {createMarkup, capitalize} from "../Utils";


class PromoSection extends React.Component {
  static contextType = LanguageContext;

  render() {
    return (
      <Container>
        <div className="container">
          <div className="row">
            <div className="col-md-2">
              <h2>{getText(this.context.lang, "home", "promoSection.title")}</h2>
            </div>
            <div className="offset-md-1 col-md-4 bloc1"
                 dangerouslySetInnerHTML={createMarkup(capitalize(getText(this.context.lang, "home", "promoSection.text1").toLowerCase()))}/>
            <div className="col-md-4 bloc1"
                 dangerouslySetInnerHTML={createMarkup(capitalize(getText(this.context.lang, "home", "promoSection.text2").toLowerCase()))}/>

          </div>
        </div>
      </Container>
    )
  }
}

export default PromoSection

const Container = styled.div`
  background:#FF6058;
  padding-top:32px ;
  padding-bottom: 32px;
  color:white;
  .bloc1{
  font-weight: 100;
  line-height: 27px;
  font-size: 18px;
   color:rgba(255,255,255,1);
  }
  .bold{
    font-weight: 700;
  }
`