import React from 'react'
import styled from 'styled-components'
import {LanguageContext} from "../Context/LanguageProvider";
import getText from "../i18n";

const squarePoints = process.env.PUBLIC_URL + '/assets/img/squarePointStorage.png';

class MaterialSectionHome extends React.Component {
  static contextType = LanguageContext;

  render() {
    const list1 = getText(this.context.lang, "home", "materialSection.list1.content")
    const list2 = getText(this.context.lang, "home", "materialSection.list2.content")

    return (
      <Container className={"container-fluid p-0 m-0 main-container"}>
        <div className="container materialSection">
          <div className="row">
            <div className="title col-sm-12 col-lg-4">
              <img src={squarePoints} alt="square points"/>
              <h2 className={'p-2'}>{getText(this.context.lang, "home", "materialSection.title")}</h2>
              <p>{getText(this.context.lang, "home", "materialSection.text1")}</p>
            </div>
            <div className="offset-lg-1 col-sm-12 col-lg-7">
              <div className="description">
                <h5>{getText(this.context.lang, "home", "materialSection.subTitle1")}</h5>
              </div>
              <div className="items">
                <div className="item">
                  <h5>{getText(this.context.lang, "home", "materialSection.list1.title")}</h5>
                  <ul>
                    {list1.map((text,index) =>
                      <li key={index}>{text}</li>)}
                  </ul>
                </div>
                <div className="item">
                  <h5>{getText(this.context.lang, "home", "materialSection.list2.title")}</h5>
                  <ul>
                    {list2.map((text,index) =>
                      <li key={index}>{text}</li>)}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    )
  }
}

export default MaterialSectionHome

const Container = styled.div`
background: #F7F7F7;
position: relative;
z-index: 0;
.container{
  padding-top: 160px;
  padding-bottom: 100px;
}
.title{
  h2{
  background: #F7F7F7;
    font-weight: 900;
    line-height: normal;
    font-size: 50px;
    text-align: left;
    color : #FF4238;
  }
  p{
    background:#F7F7F7;
    line-height: 27px;
    font-size: 18px;
    color: rgba(61, 51, 73, 0.85);
  }
  img{
    z-index: -10;
    position: absolute;
    top: -80px;
    left: -50px;
  }
}
.description{
  h5{
    font-size: 20px;
    color: #3D3349;
    font-weight: 700;
    margin-bottom: 40px;
  
  }
}
.items{
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  box-shadow: 5px 0px 22px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  padding: 32px 32px;
  border-top: 7px solid rgba(255, 56, 46, 0.7);
  .item{
    h5{
      font-size: 20px;
      color: #3D3349;
      font-weight: 700;
      margin-bottom: 16px;
    }
    ul{
      list-style: none;
      li{
        margin-bottom: 12px;
      }
    }
  }
}

`