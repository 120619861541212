/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import styled from 'styled-components'
import getText from "../i18n";
import {LanguageContext} from "../Context/LanguageProvider";
import { withRouter } from "react-router-dom";
import $ from 'jquery'

const LogoPath = process.env.PUBLIC_URL + '/assets/img/logo.svg';

class Footer extends React.Component {
  static contextType = LanguageContext;

  handleNav (dest) {
    if(dest !== 'ccg' && dest !== 'contract'){
      var speed = 400
      $('html, body').animate({scrollTop: 0}, speed)
      this.props.history.push(dest)
    }
    else if (dest === 'ccg'){
      window.open(process.env.PUBLIC_URL + '/general_conditions_mss.pdf');
    } else {
      window.open(process.env.PUBLIC_URL + '/location_contract_mss.pdf');
    }
  }

  render() {
    const listInfo = getText(this.context.lang, "footer", "info.list")
    return (
      <Container>
        <div className="wrapper footer">
          <div className="container">
            <div className="row">
              <div className=" col-lg-2">
                <img src={LogoPath} alt="company logo"/>
              </div>
              <div className=" offset-lg-2 col-lg-2 mb-4">
                <div className={'info'}>
                  {listInfo.map((text,index) =>
                    <span key={index}>{text} <br/></span>)}
                </div>
              </div>
              <div className="offset-lg-1 col-lg-2">
                <h6>
                  <span className={this.props.location.pathname === '/ccg' ? 'activelink' : 'link'} onClick={() => {this.handleNav('ccg')}}>
                    {getText(this.context.lang, "footer", "links.ccg")}
                  </span>
                </h6>
                <h6>
                  <span className={this.props.location.pathname === '/contract' ? 'activelink' : 'link'} onClick={() => {this.handleNav('contract')}}>
                    {getText(this.context.lang, "footer", "links.contract")}
                  </span>
                </h6>
                <h6>
                  <span className={this.props.location.pathname === '/insurance' ? 'activelink' : 'link'} onClick={() => {this.handleNav('insurance')}}>
                    {getText(this.context.lang, "footer", "links.insurance")}
                  </span>
                </h6>
                <h6>
                  <span className={this.props.location.pathname === '/faq' ? 'activelink' : 'link'} onClick={() => {this.handleNav('faq')}}>
                    {getText(this.context.lang, "footer", "links.FAQ")}
                  </span>
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom"/>
      </Container>
    )
  }
}

const Container = styled.footer`
  .wrapper{
    background:#3D3349;
    padding-top: 50px;
    padding-bottom: 16px;
    color: white;
    margin-top: 100px;
    background-size: cover;
    img{
      height: 30px; 
      margin-bottom: 10px;
    }
    h3{
    font-weight: 500;
    line-height: 27px;
    font-size: 18px;
    color: rgba(255, 255, 255, 0.9);
    }
    span{
      display: block;
      font-size: 16px;
      font-weight: 300;
      margin-bottom: 5px;;
      color: rgba(255, 255, 255, 0.8);
    }
    .info{
      font-weight: 100;
    }
    h6{
      font-weight: 400;
      .link {
        cursor: pointer;
      }
      .activeLink {
        cursor: none;
      }
    }
  }
  .footer-bottom{
    background: #332B3D;
    color: rgba(255,255,255,0.25);
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`


export default withRouter(Footer);
