import React, { Component } from 'react';
import {LanguageContext} from "../Context/LanguageProvider";
import TemplateScreen from "./TemplateScreen";
import ContentPartners from "../components/ContentPartners";


class Partners extends Component {
  static contextType = LanguageContext;

  render() {
/*
    const title = getText(this.context.lang, "", "");
*/
    return (
      <TemplateScreen
        title={'Partners'}
        content={<ContentPartners/>}/>
    );
  }
}

export default Partners;