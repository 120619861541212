import React from 'react'
import styled from 'styled-components'
import {LanguageContext} from "../Context/LanguageProvider";
import getText from "../i18n";

const squarePoints = process.env.PUBLIC_URL + '/assets/img/squarePointStorage.png';


class StorageSectionHome extends React.Component {
  static contextType = LanguageContext;

  render() {
    const listPart = getText(this.context.lang, "home", "storageSection.personal.list1")
    const listPro = getText(this.context.lang, "home", "storageSection.professional.list1")
    return (
      <Container className="storageSection">
        <div className="container p-0">
          <div className="row">
            <div
              className="title-wrapper col-sm-3  p-4 p-sm-0">
              <img src={squarePoints}
                   alt="square points"/>
              <h2
                className={'text-left title1 p-2'}>
                {getText(this.context.lang, "home", "storageSection.title1")}
              </h2>
              <h3
                className={'title2'}>{getText(this.context.lang, "home", "storageSection.title2")}</h3>
              <p
                className={'text'}>{getText(this.context.lang, "home", "storageSection.text")}</p>
            </div>
            <div
              className="row col-sm-12 col-md-10 offset-lg-1 col-lg-6 sections">
              <div
                className="left col-12 col-md-12 particularStorage">
                <div
                  className="left-content p-2 p-sm-0">
                  <h2>{getText(this.context.lang, "home", "storageSection.personal.title")}</h2>
                  <ul>
                    {listPart.map((text, index) =>
                      <li
                        key={index}>{text}</li>)}
                  </ul>
                  <p>{getText(this.context.lang, "home", "storageSection.personal.text1")}</p>
                </div>
              </div>
              <div
                className="right col-12 col-md-12 professionalStorage">
                <div
                  className="right-content p-2 p-sm-0">
                  <h2>{getText(this.context.lang, "home", "storageSection.professional.title")}</h2>
                  <ul>
                    {listPro.map((text, index) =>
                      <li
                        key={index}>{text}</li>)}
                  </ul>
                  <p>
                    {getText(this.context.lang, "home", "storageSection.professional.text1")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    )
  }
}

export default StorageSectionHome

const Container = styled.div`
margin-bottom: 150px;
margin-top: 100px;
.title-wrapper{
position: relative;
img{
 position: absolute;
 z-index: -10;
 top: -50px;
 left: -50px;
}
.title1{
  font-weight: 900;
  line-height: normal;
  font-size: 48px;
  color: #FF4238;
  max-width: 780px;
  background:white;
  display: block;
  padding-bottom: 10px;
  margin-bottom: 32px;
}
.title2{
  font-weight: 600;
  line-height: 32px;
  font-size: 20px;
  color: #3D3349;
}
}

.sections{
border-radius: 8px;
overflow: hidden;
box-shadow: 4px 4px 50px rgba(0, 0, 0, 0.15);
}

  .container{
    margin-bottom: 100px;
    .row{
      padding: 0;
    }
  }
  .left{
    background:#FF6058;  
  }
  .right{
    background: #3D3349
  }
  .left,.right{
  width: 100%;
    color: white;
    padding: 40px 40px 20px 40px;
    display: flex;
    justify-content: start;
    h2{
    font-weight: 700;
    font-size: 36px;
      margin-bottom: 16px;
    }
    ul{
      list-style: none;
    }
    li,p{
      font-weight: 400;
      line-height: 180%;
      font-size: 16px;
      max-width: 500px;
    }
  }
  .content{
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }
`