import React from "react";
import {LanguageContext} from "../Context/LanguageProvider";

export default class CustomSlide extends React.Component {
  static contextType = LanguageContext;

  getDescription(item) {
    switch (this.context.lang) {
      case 'fr':
        return item.descriptionFR
      case 'nl':
        return item.descriptionNL
      default:
        return item.descriptionEN;
    }
  }

  render() {
    const {item, index, ...props} = this.props;
    const area = parseFloat(item.area)
    const pic = item.pic
    return (
      <div {...props} style={styles.container}>
        <div className="" style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          flex: 1,
        }}>
          <header>
            <div
              style={styles.volume}>{area.toFixed(2)} m²
            </div>
          </header>
          <img src={pic} alt={area.toFixed(2)} style={styles.image}></img>
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    display: 'flex',
    height: 200,
    position: 'relative',
    boxShadow: '5px 5px 30px rgba(0, 0, 0, 0.1)',
    borderRadius: 2,
    marginRight: 30,
    marginBottom: 50,
    backgroundColor: 'white',
    marginTop: 50,
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 16,
    maxWidth: 320,
  },
  volume: {
    display: 'block',
    fontWeight: 900,
    fontSize: 20,
    marginBottom: 8,
    color: '#3D3349'
  },
  image: {
    height: 100,
    objectFit: "contain",
  },
}