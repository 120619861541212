export default {
  nav: {
    home: {
      en: 'Home',
      fr: 'Accueil',
      nl: 'Home'
    },
    storagePro: {
      en: 'Professionnal Storage',
      fr: 'Stockage Professionnel',
      nl: 'Professionele opslag'
    },
    storageCons: {
      en: 'Consumer Storage',
      fr: 'Stockage particulier',
      nl: 'Opslag voor de consument'
    },
    packingMaterial: {
      en: 'Packing Material',
      fr: 'Matériel d\'emballage',
      nl: 'Verpakkingsmateriaal'
    },
    contact: {
      en: 'Contact',
      fr: 'Contact',
      nl: 'Contact'
    },
    price: {
      en: 'Our price',
      fr: 'Nos prix',
      nl: 'Onze prijs'
    }
  },
  header: {
    text1: {
      en: 'Finally the self storage affordable for everybody in Brussels',
      fr: 'Enfin le Self Storage accessible pour tous à Bruxelles',
      nl: 'Eindelijk opslagruimtes voor iedereen in Brussel'
    },
    buttonLocation: {
      en: 'Rent a box',
      fr: 'Louer un box',
      nl: 'Een doos huren'
    }
  },
  aboutSection: {
    title: {
      en: 'What is a self storage',
      fr: 'Qu’est ce que le self storage ?',
      nl: 'Wat is opslag ?',
    },
    aboutItems: {
      pAndE: {
        title: {
          en: 'Individuals and Companies',
          fr: 'Particulier et Entreprise',
          nl: 'Particulieren en bedrijven'
        },
        text1: {
          en: "Self Storage allows you to store your belongings while saving a maximum of costs because you do part of the work yourself: you pack your belongings, transport them to My Self Storage and store them yourself in your private space. In this way, all unnecessary costs are eliminated.",
          fr: "Le Self Storage vous permet de stocker vos affaires en économisant un maximum de frais parce que vous faites une partie du travail vous-mêmes : vous emballez vos affaires, vous les transportez jusque chez My Self Storage et vous les rangez vous mêmes dans votre espace privé. De cette façon, tous les frais inutiles disparaissent. ",
          nl: "Met Self Storage kunt u uw spullen opslaan en tegelijkertijd een maximum aan kosten besparen omdat u een deel van het werk zelf doet: u verpakt uw spullen, vervoert ze naar My Self Storage en bewaart ze zelf in uw eigen ruimte. Op deze manier worden alle onnodige kosten geëlimineerd. "
        }
      },
      space: {
        title: {
          en: 'Need for space',
          fr: 'Besoin d’espace ',
          nl: 'Behoefte aan ruimte'
        },
        text1: {
          en: "My Self Storage is THE solution for you at a price that is finally affordable in Brussels. Whether it is for a week, 1 or 6 months or even several years, we have the right solution to free up space at home. (My Self Storage can save you up to 50% on your storage costs)",
          fr: "My Self Storage est LA solution pour vous à un prix enfin abordable à Bruxelles. Que ce soit pour une semaine, 1 ou 6 mois voire plusieurs années, nous avons la solution qui convient pour libérer de la place chez vous. (My Self Storage  peut vous faire économiser jusqu'à 50% sur vos couts de stockage)",
          nl: "My Self Storage is DE oplossing voor u tegen een betaalbare prijs in Brussel. Of het nu voor een week, 1 of 6 maanden of zelfs meerdere jaren is, wij hebben de juiste oplossing om thuis ruimte vrij te maken. (My Self Storage kan u tot 50% besparen op uw opslagkosten)",
        }
      },
      price: {
        title: {
          en: 'The best prices on the market',
          fr: 'Les meilleurs prix du marché',
          nl: 'De beste prijzen op de markt'
        },
        text1: {
          en: "At My Self Storage, we guarantee you the best market prices at the optimal conditions for storing your goods with better services.",
          fr: "Chez My Self Storage, nous vous garantissons les meilleurs prix du marché aux conditions optimales de stockage de vos biens avec meilleur services.",
          nl: "Bij My Self Storage garanderen wij u de beste marktprijzen tegen de optimale condities voor de opslag van uw goederen met betere service."
        }
      },
      buttonMore: {
        en: 'More info',
        fr: 'En savoir plus',
        nl: 'Meer info',
      }
    }
  },
  locationSection: {
    title: {
      en: 'Box rental',
      fr: 'Location de box',
      nl: 'Verhuur van dozen'
    },
    subTitle: {
      en: 'The first month of rental at 1€ for all sizes of boxes (only for new customers and provided a contract period of minimum 2 months)',
      fr: 'Le premier mois de location à 1€ pour toutes les tailles des boxes ( uniquement pour nouveaux clients et à condition d’une durée de contrat de minimum 2 mois)',
      nl: 'De eerste maand van huur aan 1€ voor alle maten dozen (alleen voor nieuwe klanten en mits een contractperiode van minimum 2 maanden)'
    },
    after: {
      en: 'In addition to our promotion of <span style="color: red">1€</span> the first month, the second month at <span style="color: red">50%</span> (only for new customers and provided a minimum contract period of 6 months)',
      fr: 'En plus de notre promotion de <span style="color: red">1€</span> le premier mois, le deuxième mois à <span style="color: red">50%</span> ( uniquement pour nouveaux clients et à condition d’une durée de contrat de minimum 6 mois )',
      nl: 'Naast onze promotie van <span style="color: red">1€</span> de eerste maand, de tweede maand tegen <span style="color: red">50%</span> (alleen voor nieuwe klanten en mits een minimale contractperiode van 6 maanden)',
    }
  },
  promoSection: {
    title: {
      en: 'Opening promotion',
      fr: "Promo d'ouverture",
      nl: 'Opening promotie '
    },
    text1: {
      en: "<span class='bold'>on TOP of our 50% promotion</span>, we propose you <span class='bold'>a 10% discount</span> on the standrad prix of you box for the WHOLE duration of your contract.",
      fr: "My Self Storage vous propose une promo d’ouverture <span class='bold'>EN PLUS DE NOTRE PROMO DE 50 %</span>, <span class='bold'>recevez une remise de 10%</span> sur le prix standard de votre box pour TOUTE la durée de votre location.",
      nl: "My Self Storage stelt u voor <span class='bold'>BOVENOP ONZE PROMO VAN 50%, een discount van 10%</span> op de standaard prijs van uw box zolang u bij ons blijft. "
    },
    text2: {
      en: "Please contact us for any question on <span class='bold'>02/647.22.31</span> and/or <span class='bold'>0479/08.67.56</span> or by mail <span class='bold'>info@myselfstorage.biz</span>",
      fr: "Consultez nous pour toute information au <span class='bold'>02/647.22.31</span> et/ou <span class='bold'>0479/08.67.56</span> ou par mail <span class='bold'>info@myselfstorage.biz</span>",
      nl: "Contacteer ons voor meer info op <span class='bold'>02/647.22.31</span> en/of <span class='bold'>0479/08.67.56</span> of per mail <span class='bold'>info@myselfstorage.biz</span>"
    }
  },
  storageSection: {
    title1: {
      en: 'Consumers and Professionnal Storage',
      fr: 'Stockage Profesionnel et Particulier',
      nl: 'Raadpleeg onze prijzen volgens doosgrootte',
    },
    title2:{
      en: 'Consumers and Professionnal Storage',
      fr: 'Le stockage de vos biens dans les conditions les plus appropriées et en toute sécurité est possible !',
      nl: 'Raadpleeg onze prijzen volgens doosgrootte',
    },
    text:{
      en: 'Consumers and Professionnal Storage',
      fr: 'De l\'entreposage de vos meubles les plus précieux à la conservation d\'archives, nous vous proposons service d\'entreposage sécurisé, chauffé et climatisé.',
      nl: 'Raadpleeg onze prijzen volgens doosgrootte',
    },
    personal: {
      title: {
        en: 'Consumers',
        fr: 'Particuliers',
        nl: 'Particulieren',
      },
      list1: {
        en: ['- You are missing space ?', '- You move ?', '- You are doing works at home ?'],
        fr: ['- Vous êtes en manque d’espace ?', '- Vous déménagez ?', '- Vous faites des travaux ?'],
        nl: ['- U mist plaats thuis, ?', '- U verhuist ?', '- Ofwel doet u werken ?']
      },
      text1: {
        en: 'Whatever your need My Self Storage is there to help you. Whatever it is for 1 week or 6 months, for 1 or 100 m2, we have a solution for you. My Self Storage can help you to decrease your storage costs up to 50%. In comparison to a classical storage, you ca access your private storage 7/7 from 6 to 23 hours.',
        fr: 'Quel que soit votre besoin, My Self Storage est là pour vous aider. Que ce soit pour une semaine ou 6 mois, pour 1 ou 100 m2, nous avons une solution à vous proposer. My Self Storage peut vous faire économiser jusqu’à 50% sur vos couts de stockage. Contrairement à un garde meuble traditionnel, vous  gardez l’accès à votre box quand bon vous semble, 7 jours sur 7. My Self Storage   vous  propose des solutions de libre entreposage pour les particuliers et les entreprises, la sécurité de vos biens est notre première priorité. Nos entrepôts sont reliés à une centrale d’alarme avec patrouille d’intervention 24/7',
        nl: 'Naargelang uw nood is My Self Storage daar om u te helpen. Voor een week of 6 maanden , voor 1 of 100 m2, we hebben een oplossing voor idedereen. My Self Storage kan u tot 50% kosten sparen. Integedeel van een traditioneel meubel bewaring, u heeft toegang naar uw box elke dag, 365 dagen per jaar, van 6 tot 23 uur. My Self Storage stelt u voor oplossingen voor opsalg zowel voor particulieren as voor professionele. De veiligheid van uw spullen is onze grooste objektief. Onze opslag is iverbonden  met een centrale bewaking systeem met 24/7 interventie in geval van alarm'
      }

    },
    professional: {
      title: {
        en: 'Professionals',
        fr: 'Professionnels',
        nl: 'Professioneel',
      },
      list1: {
        en: ['- You need space ?', '- Your storage is overloaded ?', '- You need place for your archives ?'],
        fr: ['- Besoin d’espace ?', '- Votre stock professionnel déborde ?', '- Vous cherchez de la place pour vos archives ?'],
        nl: ['- U heeft plaats nodig ?', '- Uw stock is vol ?', '- U zoekt plaats voor uw archieven ?']
      },
      text1: {
        en: 'We offer you our warehouse, warmed, secured 24/24, with free access with your own badge from 6 to 23 hours 7/7, 365 days a year. Our warehouse are on line with a control room 24/7',
        fr: 'Nous vous proposons nos entrepôts, chauffés, ventilés et surtout sécurisés 24/24 avec libre accès par badge personnel de 6 à 23h tous les jours y compris le weekend, 365 jours par an. Nos entrepôts sont reliés à une centrale d’alarme avec patrouille d’intervention 24/7. ',
        nl: 'We stellen u voor onze opslag, verwarmd en beveiligd 24/7. Met uw persoonlijke badge krijgt u vrije toegang naar uw box 24/24 , van 6 tot 23 uur inklusief in weekend, 365 dagen per jaaar. Onze opslag is verbonden met een centrale bewaking met interventie in geval van alarm 24/24'
      }
    }
  },
  contactSection: {
    title: {
      en: 'Schedules and Access',
      fr: 'Horaires et Accès',
      nl: 'Dienstregelingen en toegang',
    },
    subTitle1: {
      en: 'access and opening hours',
      fr: "Horaire d'acces et d'ouverture",
      nl: "toegang en openingstijden"
    },
    list1: {
      en: ['7 days a week access from 6am to 11pm', ' 365 days a year'],
      fr: ['accès 7 jours sur 7 de 6h à 23h00', '365 jours par an'],
      nl: ['7 dagen per week van 6.00 tot 23.00 uur', ' 365 dagen per jaar']
    },
    subTitle2: {
      en: 'Schedule of reception at My Self Storage',
      fr: "Horaire de l'acceuil chez My Self Storage",
      nl: "Tijdschema van de ontvangst bij My Self Storage"
    },
    list2: {
      en: ['Monday to Friday from 9am to 1pm and from 1.30pm to 6.30pm', ' Saturday from 9.30am to 4.30pm'],
      fr: ['Du lundi au vendredi de 9h à 13h et de 13h30 à 18h30', 'Le samedi de 9h30 a 16h30'],
      nl: ['Maandag tot vrijdag van 9u tot 13u en van 13u30 tot 18u30', 'zaterdag van 9u30 tot 16u30']
    }
  },
  materialSection: {
    title: {
      en: 'Packaging material',
      fr: 'Matériel d\'emballage',
      nl: 'Verpakkings materiaal',
    },
    subTitle1: {
      en: "Do you need packing material for your move? My Self Storage offers you:",
      fr: "Vous avez besoin de matériel d’emballage pour votre déménagement ? My Self Storage vous propose :",
      nl: "Heeft u verpakkingsmateriaal nodig voor uw verhuizing? My Self Storage biedt u:"
    },
    text1: {
      en: 'At the best market price.',
      fr: 'Au meilleur prix du marche.',
      nl: 'Tegen de beste marktprijs.',
    },
    list1: {
      title: {
        en: 'Moving boxes of different sizes',
        fr: 'Cartons de déménagement de différentes tailles',
        nl: 'Verhuisdozen van verschillende afmetingen',
      },
      content: {
        en: [
          '- Barrel cardboard : 45*45*70 cm', 
          '- Moving box: 53*33*35 cm', 
          '- Book cardboard: 33*25.5*35 cm',
          '- Wardrobe box: 93,5*40*40*55 cm',
        ],
        fr: [
          '-	Carton baril 45*45*70 cm', 
          '- Carton de déménagement : 53*33*35  cm', 
          '- Carton livre :  33*25.5*35 cm',
          '- Carton penderie :  93,5*40*55 cm',
        ],
        nl: [
          '- Vatkarton : 45*45*70 cm', 
          '- Verhuisdoos: 53*33*35 cm', 
          '- Boek karton: 33*25.5*35 cm',
          '- Garderobedoos: 93,5*40*40*55 cm',
        ]
      }
    },
    list2: {
      title: {
        en: 'Packaging accessories',
        fr: 'Accessoires d’emballage',
        nl: 'Verpakkingsaccessoires',
      },
      content: {
        en: ['- adhesive tape 66 x 5 cm', '- bubble wrap 15 x 1 m', '- tissue paper 75 x 50 cm', '- 100 sheets', '- moving blanket 200 x 150 cm.'],
        fr: ['- ruban adhésif 66 x 5 cm', '- papier bulle 15 x 1 m', '- papier de soie 75 x 50 cm', '- 100 feuilles', '- couverture de déménagement 200 x 150 cm.'],
        nl: ['- plakband 66 x 5 cm', '- noppenfolie 15 x 1 m', '- tissuepapier 75 x 50 cm', '- 100 vellen', '- bewegende deken 200 x 150 cm.']
      }
    },
  }
}