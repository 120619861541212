import React from 'react'
import Responsive from 'react-responsive-decorator';
import { Link } from 'react-router-dom';
import MobileNav from "./MobileNav";
import DesktopNav from "./DesktopNav";
import $ from 'jquery';
import getText from "../i18n";
import {LanguageContext} from "../Context/LanguageProvider";


class Navbar extends React.Component {
  static contextType = LanguageContext;

  constructor(props) {
    super(props);


    this.toggle = this.toggle.bind(this);
    this.child = React.createRef();
    this.state = {
      isMobile: false
    };
  }


  componentDidMount() {
    this.props.media({maxWidth: 1286}, () => {
      this.setState({
        isMobile: true
      });
    });

    this.props.media({minWidth: 1286}, () => {
      this.setState({
        isMobile: false
      });
    });
  }

  toggle() {
    this.setState({
      isMobile: !this.state.isMobile,
      scrolling: false,
    });
  }

  scroll(ev, section, offset = 0, isMobile) {
    ev.preventDefault()
    var page = $(`.${section}`)
    var speed = 750
    $('html, body').animate({scrollTop: $(page).offset().top + offset}, speed)
    if (isMobile){
      this.child.current.toggleMobileMenu()
    }
  }

  render() {
    return (
      <div>
        {this.state.isMobile ?
          <MobileNav ref={this.child}>
            <li className="nav-item">
              <span
                onClick={(ev) => this.scroll(ev, 'aboutSection', 0, true)}>
                {getText(this.context.lang, "home", "nav.home")}</span>
            </li>
            <li className="nav-item">
              <span
                onClick={(ev) => this.scroll(ev, 'locationSection', 0, true)}>
                {getText(this.context.lang, "home", "nav.price")}</span>
            </li>
            <li className="nav-item">
              <span
                onClick={(ev) => this.scroll(ev, 'particularStorage', -120, true)}
              >{getText(this.context.lang, "home", "nav.storageCons")}</span>
            </li>
            <li
              className="nav-item">
              <span
                onClick={(ev) => this.scroll(ev, 'professionalStorage', -120, true)}
              >{getText(this.context.lang, "home", "nav.storagePro")}</span>
            </li>
            <li className="nav-item">
              <span
                onClick={(ev) => this.scroll(ev, 'materialSection', 0, true)}
              >{getText(this.context.lang, "home", "nav.packingMaterial")}</span>
            </li>
            <li
              className="nav-item contact-button">
              <button
                onClick={(ev) => {}}>
                <Link style={{color: 'white'}}
                  to={'/contact'}>
                    {getText(this.context.lang, "home", "nav.contact")}
                </Link>
              </button>
            </li>
          </MobileNav> :
          <DesktopNav>
            <li className="nav-item">
              <span
                onClick={(ev) => this.scroll(ev, 'aboutSection', 0, false)}>
                {getText(this.context.lang, "home", "nav.home")}</span>
            </li>
            <li className="nav-item">
              <span
                onClick={(ev) => this.scroll(ev, 'locationSection', 0, false)}>
                {getText(this.context.lang, "home", "nav.price")}</span>
            </li>
            <li className="nav-item">
              <span
                onClick={(ev) => this.scroll(ev, 'particularStorage', -120, false)}
              >{getText(this.context.lang, "home", "nav.storageCons")}</span>
            </li>
            <li
              className="nav-item">
              <span
                onClick={(ev) => this.scroll(ev, 'professionalStorage', -120, false)}
              >{getText(this.context.lang, "home", "nav.storagePro")}</span>
            </li>
            <li className="nav-item">
              <span
                onClick={(ev) => this.scroll(ev, 'materialSection', false)}
              >{getText(this.context.lang, "home", "nav.packingMaterial")}</span>
            </li>
            <li
              className="nav-item contact-button">
              <button
                onClick={(ev) => {}}>
                <Link style={{color: 'white'}}
                  to={'/contact'}>
                    {getText(this.context.lang, "home", "nav.contact")}
                </Link>
              </button>
            </li>
          </DesktopNav>
        }
      </div>
    )
  }
}

export default Responsive(Navbar)
