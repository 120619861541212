import React, { Component } from 'react';
import styled from 'styled-components'
import HeaderHome from "../components/HeaderHome";
import AboutSectionHome from "../components/AboutSectionHome";
import StorageSectionHome from "../components/StorageSectionHome";
import Footer from "../components/Footer";
import ContactSectionHome from "../components/ContactSectionHome";
import MaterialSectionHome from "../components/MaterialSectionHome";
import LocationSectionHome from "../components/LocationSectionHome";
import PromoSection
  from "../components/PromoSectionHome";
import {Lightbox} from 'react-modal-image';



class HomeScreen extends Component {

  state = { hideNav: false, modal: null }

  render() {
    return (
      <Container>
        <HeaderHome hide={this.state.hideNav}/>
        <PromoSection/>
        <AboutSectionHome />
        <LocationSectionHome toResa={(index) => {this.props.history.push('/reservation/'+index)}} />
        <StorageSectionHome />
        <MaterialSectionHome/>
        <ContactSectionHome />
        <Footer />
        {
          this.state.modal && 
          <Lightbox large={this.state.modal} 
                   alt={"Full zoom"} 
                   onClose={() => this.setState({hideNav: false, modal: null})}
          />

        }
      </Container>
    );
  }
}


const Container = styled.div`

`

export default HomeScreen;
