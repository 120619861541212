import _ from "lodash";
import Home from "./Home";
import About from "./About";
import Footer from "./Footer";
import Faq from "./Faq";
import Insurance from "./Insurance";
import Contact from './Contact';
import Template from "./Template";
import Partners from "./Partners";
import Reservation from "./Reservation";

const keyed = {
  home: Home,
  about: About,
  footer: Footer,
  faq: Faq,
  insurance: Insurance,
  contact: Contact,
  template: Template,
  partners: Partners,
  reservation: Reservation,
}


export default function getText(lang, category, path) {
  return _.get(keyed[category], path + "." + lang.toLowerCase(), "Invalid getText path: " + path);
}

export const allAt = (category, path) => {
  return _.keys(_.get(keyed[category]), path, "Invalid getText path: " + path);
}
