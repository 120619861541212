import React, { Component } from 'react';
import {LanguageContext} from "../Context/LanguageProvider";
import TemplateScreen from "./TemplateScreen";
import ContentFAQScreen from "../components/ContentFAQScreen";


class FAQScreen extends Component {
  static contextType = LanguageContext;

  render() {
/*
    const title = getText(this.context.lang, "", "");
*/
    return (
      <TemplateScreen
        title={'FAQ'}
        content={<ContentFAQScreen/>}/>
    );
  }
}

export default FAQScreen;
