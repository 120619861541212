import React from 'react'
import {LanguageContext} from "../Context/LanguageProvider";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LocationSidePanel from "./LocationSidePanel";
import ReservationBoxCard from "./ReservationBoxCard";

import * as validators from "../validators";
import * as config from '../config';

export default class LocationSectionHome extends React.Component {
  
  /* Data initialization */

  static contextType = LanguageContext;
  
  state = {
    boxesArray: [],
    showForm: false,
    focusBox: 0,
    sideForm: {
      groundFloor: false,
      name: {
        value: '',
        error: '',
        validators: [validators.notEmpty],
        sanitizers: [validators.trim],
      },
      firstName: {
        value: '',
        error: '',
        validators: [validators.notEmpty],
        sanitizers: [validators.trim],
      },
      email: {
        value: '',
        error: '',
        validators: [validators.notEmpty, validators.validEmail],
        sanitizers: [validators.trim, validators.emailSanit],
      },
      phone: {
        value: '',
        error: '',
        validators: [validators.notEmpty, validators.validPhone],
        sanitizers: [validators.trim],
      },
      dateStart: "", // {
      //   value: '',
      //   error: '',
      //   validators: [validators.notEmpty],
      //   sanitizers: [validators.trim],
      // },
      dateEnd: "", // {
      //   value: '',
      //   error: '',
      //   validators: [validators.notEmpty],
      //   sanitizers: [validators.trim],
      // },
      message: {
        value: '',
        error: '',
        validators: [validators.notEmpty],
        sanitizers: [validators.trim],
      },
      captcha: {
        value: '',
        error: '',
        validators: [validators.notEmpty],
        sanitizers: [],
      }
    },
    submit: {
      loading: false,
      success: false,
    }
  }

  
  componentDidMount() {
    fetch(config.box)
      .then((response) => response.json())
      .then((boxesArray) => {
        let sorted = boxesArray.sort((b, a) => b.area - a.area)
        this.setState({boxesArray: sorted})
        if(this.props.initialBox){
          this.onBoxFocus(this.props.initialBox, { preventDefault: () => {} })
        }
      })
      .catch(function (error) {
        console.log('Il y a eu un problème avec l\'opération fetch: ' + error.message);
      });
  }

  async clearForm (){
    let newForm = {}
    for(let fieldName in this.state.sideForm){
      if(typeof this.state.sideForm[fieldName] === 'object'){
        newForm[fieldName] = {
          value: '',
          error: '',
          validators: this.state.sideForm[fieldName].validators,
          sanitizers: this.state.sideForm[fieldName].sanitizers,
        }
      } else {
        newForm[fieldName] = this.state.sideForm[fieldName]
      }
    }
    await this.setState(prev => ({...prev, sideForm: newForm}))
  }

  /* Submission */
  submitData(toSubmit) {
    console.log(JSON.stringify(toSubmit))
    this.setState(prev => ({...prev, submit: {...prev.submit, loading: true, success: false}}))
      fetch(config.reserveMailUrl, {
        method: 'POST',
        body: JSON.stringify(toSubmit),
        headers: {
          'Content-Type': 'application/json'
        },
      })
      .then((response) => {
        if(response.ok){
          this.setState(prev => ({...prev, submit: {...prev.submit, loading: false, success: true}}))
        } else {
          throw response;
        }
      })
      .catch((err) => {
        this.setState(prev => ({...prev, submit: {...prev.submit, loading: false, success: false}}))
      })
  }

  async onSubmit() {
    let toSubmit = {
      box: ''  + this.state.boxesArray[this.state.focusBox].area + 'm²'
    }
    let errored = false
    for(let fieldName in this.state.sideForm){
      if(typeof this.state.sideForm[fieldName] === 'object'){
        const { newValue, error } = this.validateField(fieldName, this.state.sideForm[fieldName].value)
        if(error){
          await this.editFormState(fieldName, { ...this.state.sideForm[fieldName], error })
          errored = true
        } else {
          toSubmit[fieldName] = newValue
        }
      }else{
        toSubmit[fieldName] = this.state.sideForm[fieldName]
      }
    }
    if(!errored || true){
      this.submitData(toSubmit)
    }
  }

  /* UI handlers */

  async onCaptchaChange(value){
    await this.onFieldChange('captcha', value || "", true)
  }

  async editFormState(field, value){
    let newState = {...this.state, sideForm: { ...this.state.sideForm }}
    newState.sideForm[field] = value
    await this.setState(newState) 
  }

  async onFieldChange(fieldName, ev, special) {
    let value = null;
    if(!special){
      ev.preventDefault()
      value = ev.target.value
    }else{
      value = ev
    }
    const {error, newValue} = this.validateField(fieldName, value)
    if(special){
      await this.editFormState(fieldName, newValue)
    } else {
      await this.editFormState(fieldName, { ...this.state.sideForm[fieldName], error, value: newValue })
    }
  }

  validateField(fieldName, value){
    if(typeof this.state.sideForm[fieldName] !== 'object'){
      return {newValue: value, error: false}
    }
    let newValue = value
    //sanitize
    for(let i = 0 ; i < this.state.sideForm[fieldName].sanitizers.length ; ++i){
      newValue = this.state.sideForm[fieldName].sanitizers[i](newValue);
    }
    let error = '';
    //validate
    for(let i = 0 ; i < this.state.sideForm[fieldName].validators.length && !error ; ++i){
      error = this.state.sideForm[fieldName].validators[i](newValue);
    }
    return { newValue, error }
  }

  async closeSidePanel() {
    await this.setState(prev => ({...prev, showForm: false}))
    await this.clearForm()
  }

  async onBoxFocus(index, ev) {
    ev.preventDefault()
    await this.setState(prev => ({...prev, showForm: true, focusBox: index}))
    if(!parseFloat(this.state.boxesArray[index].priceUpstairs)){
      await this.onFieldChange('groundFloor', true, true)
    }else{
      await this.onFieldChange('groundFloor', false, true)
    }
  }

  onImgClick(uri, ev) {
    ev.preventDefault()
    this.props.showModal(uri)
  }

  render() {
    return (
      <React.Fragment>
        <LocationSidePanel
          updateFloor={(newValue) => { this.setState(prev => ({...prev, sideForm: {...prev.sideForm, groundFloor: newValue}})) }}
          boxes={this.state.boxesArray}
          selected={this.state.focusBox}
          form={this.state.sideForm}
          show={this.state.showForm}
          close={this.closeSidePanel.bind(this)}
          onFieldChange={this.onFieldChange.bind(this)}
          onCaptchaChange={this.onCaptchaChange.bind(this)}
          showModal={this.props.showModal}
          onSubmit={this.onSubmit.bind(this)}
          submitting={this.state.submit}
          onCancel={this.closeSidePanel.bind(this)}
          />
        {
          this.state.boxesArray.map((box, index) => 
          <ReservationBoxCard 
            key={index} 
            box={box}
            onReserve={this.onBoxFocus.bind(this, index)}
            onImgClick={this.onImgClick.bind(this, box.pic)}
            />
          )
        }
      </React.Fragment>
    )
  }
}
