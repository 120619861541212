export default {
  title: {
    en: 'Need informations !',
    fr: 'Besoin d’un renseignement !',
    nl: 'Informatie nodig !',
  },
  lines: {
    en: [
      'Contact us at the 02/647.22.31',
      'from monday to friday from 9h to 18h30',
      'saturday from 9h30 to 16h30',
      'My Self Storage : Chaussée de Ninove 357, 1070 Bruxelles'
    ], 
    fr: [
      'Contactez-nous 02/647.22.31',
      'du lundi au vendredi de 9h à 18h30',
      'samedi de 9h30 a 16h30',
      'My Self Storage : Chaussée de Ninove 357, 1070 Bruxelles',
    ],
    nl: [
      'Contact ons op 02/647.22.31',
      'Maandag tot Dinsdag vanaaf 9h tot 18h30',
      'zaterdag vanaaf 9h30 tot 16h30',
      'My Self Storage : Ninoofsesteenweg 357, 1070 Brussels'
    ],
  },
  bold: {
    en: 'Email : info@myselfstorage.biz',
    fr: 'Email : info@myselfstorage.biz',
    nl: 'Email : info@myselfstorage.biz',
  },
  form: {
    cancel: {
      en: 'Cancel',
      fr: 'Annuler',
      nl: 'Afzeggen'
    },
    title: {
      en: 'Contact us by e-mail',
      fr: 'Contactez-nous par e-mail',
      nl: 'Contacteer ons per e-mail',
    },
    description: {
      en: 'For any questions, please use the form below. We will answer you as soon as possible',
      fr: 'Pour toutes questions, veuillez utiliser le formulaire ci-dessous. Nous vous répondrons le plus vite possible',
      nl: 'Voor vragen kunt u gebruik maken van onderstaand formulier. Wij zullen u zo spoedig mogelijk antwoorden',
    },
    fields: {
      name: {
        en: 'Name',
        fr: 'Nom',
        nl: 'Naam',
      },
      firstName: {
        en: 'First Name',
        fr: 'Prénom',
        nl: 'Voornaam',
      },
      email: {
        en: 'Email',
        fr: 'Email',
        nl: 'Email',
      },
      phone: {
        en: 'Phone',
        fr: 'Téléphone',
        nl: 'Telefoon',
      },
      topic: {
        en: 'Topic',
        fr: 'Objet',
        nl: 'Kwestie',
      },
      message: {
        en: 'Message',
        fr: 'Message',
        nl: 'Bericht',
      },
    },
    error: {
      notEmpty: {
        fr: 'Ce champ ne peut pas être vide',
        en: 'This field cannot be empty',
        nl: 'Dit veld mag niet leeg zijn'
      },
      validEmail: {
        fr: 'Veuillez entrer une adresse mail valide',
        en: 'Please enter a valid email address',
        nl: 'Vul een geldig e-mailadres in'
      },
      validPhone: {
        fr: 'Veuillez entrer un numéro de téléphone valide',
        en: 'Please enter a valid phone number',
        nl: 'Vul een geldig telefoon nummer in',
      },
    },
    submit: {
      fr: 'Envoyer',
      nl: 'Zenden',
      en: 'Submit',
    },
    submitSuccess: {
      fr: 'Merci pour votre message!',
      nl: 'Bedankt voor uw bericht!',
      en: 'Thank you for your message!'
    },
    submitError: {
      fr: 'Oops il y a eu un problème...',
      nl: 'Oeps er was een probleem...',
      en: 'Oops there was a problem...',
    }
  }
}