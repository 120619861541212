import React, {Component} from 'react'
import {LanguageContext} from "../Context/LanguageProvider"
import TemplateScreen from "./TemplateScreen"
import getText from "../i18n"
import ContentAboutScreen
  from "../components/ContentAboutScreen"

class AboutScreen extends Component {
  static contextType = LanguageContext

  render() {
    const title = getText(this.context.lang, "about", "headerTitle")
    return (
      <TemplateScreen
        title={title}
        content={<ContentAboutScreen/>}/>
    );
  }
}

export default AboutScreen;
