export default {
  info: {
    list: {
      en: ['357 Chaussee de Ninove', ' 1070 Anderlecht', 'info@myselfstorage.biz ', 'T: 02/647.22.31', 'M: 0479/08.67.56'],
      fr: ['357 Chaussee de Ninove', ' 1070 Anderlecht', 'info@myselfstorage.biz ', 'T: 02/647.22.31', 'M: 0479/08.67.56'],
      nl: ['357 Ninoofsesteenweg', ' 1070 Anderlecht', 'info@myselfstorage.biz ', 'T: 02/647.22.31', 'M: 0479/08.67.56']
    }
  },
  links: {
    ccg: {
      en: 'Contract & general conditions',
      fr: 'Contrat et conditions générales',
      nl: 'Contract en generale condities',
    },
    insurance: {
      en: 'insurance',
      fr: 'assurance',
      nl: 'Verzekering',
    },
    contract: {
      en: 'Lease contract',
      fr: 'Contrat de location',
      nl: 'Leasecontract',
    },
    FAQ: {
      en: 'FAQ',
      fr: 'FAQ',
      nl: 'FAQ',
    },
    partners: {
      en: 'Partners',
      fr: 'Partenaires',
      nl: 'Partner',
    }
  }
}