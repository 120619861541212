import React from 'react'
import styled from 'styled-components'
import {LanguageContext} from "../Context/LanguageProvider";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import getText from "../i18n";
import CustomSlide
  from "./CustomSilde";
import {createMarkup} from "../Utils"
import * as config from '../config'
import {Link} from "react-router-dom";
import Responsive from 'react-responsive-decorator';

const squarePoints = process.env.PUBLIC_URL + '/assets/img/squarePointStorage.png';
const couloir = process.env.PUBLIC_URL + '/assets/img/couloir.png';
const arrowPath = process.env.PUBLIC_URL + '/assets/img/redArrow.png';
const facade = process.env.PUBLIC_URL + '/assets/img/facade.png';

class LocationSectionHome extends React.Component {
  
  static contextType = LanguageContext;
  
  state = {
    boxesArray: [],
    isMobile: false,
  }

  componentDidMount() {

    this.props.media({maxWidth: 500}, () => {
      this.setState({
        isMobile: true
      });
    });

    this.props.media({minWidth: 500}, () => {
      this.setState({
        isMobile: false
      });
    });

    fetch(config.box)
      .then((response) => response.json())
      .then((boxesArray) => {
        let sorted = boxesArray.sort((b, a) => b.area - a.area)
        this.setState({boxesArray: sorted})
      })
      .catch(function (error) {
        console.log('Il y a eu un problème avec l\'opération fetch: ' + error.message);
      });
  }

  render() {
    return (
      <Container className="locationSection">
        <div className="container">
          <div className="wrapper">
            <div className="row">
              <div className={"col-12 col-md-6"}>
                <h2>{getText(this.context.lang, "home", "locationSection.title")}</h2>
                <h6 className={"text1"}
                    dangerouslySetInnerHTML={createMarkup(getText(this.context.lang, "home", "locationSection.subTitle"))}></h6>
                <h6 className={"text2"}
                    dangerouslySetInnerHTML={createMarkup(getText(this.context.lang, "home", "locationSection.after"))}></h6>
                <Link
                  className={"button-more"}
                  to={'/reservation'}>
                  {getText(this.context.lang, "home", "aboutSection.aboutItems.buttonMore")}
                  <img src={arrowPath} alt=""/>
                </Link>
              </div>
              <div className={"col-12 col-md-6"}>
                <Slider initialSlide={1} slidesToShow={1} autoplay={true} autoplaySpeed={3000}>
                  <img src={couloir} alt="couloir"
                       className={"image"}/>
                  <img src={facade} alt="facade"
                       className={"image"}/>
                </Slider>
              </div>
            </div>
            <div className="row">
              <div
                className="col-md-12 col-12 cards-container">
                <Slider {...settings} slidesToShow={window.innerWidth > 500 ? 3 : 1}>
                  {this.state.boxesArray.map((item, index) =>
                    <CustomSlide key={index}
                                item={item}
                                onClick={(ev) => { 
                                  ev.preventDefault()
                                  this.props.toResa(index)
                              }} />
                  )}
                </Slider>
                <img src={squarePoints}
                     className={"squarePoints"}
                     alt="square points"/>
              </div>
            </div>
          </div>
        </div>
      </Container>
    )
  }
}

export default Responsive(LocationSectionHome)

const Container = styled.div`
background:#f4f4f4;
position: relative;
z-index: 0;
padding-top: 120px;
padding-bottom: 120px;
margin-top: 150px;
margin-bottom: 48px;
h2{
font-style: normal;
font-weight: 900;
line-height: 64px;
font-size: 56px;
color: rgba(255, 56, 46, 0.95);
}
h5{
font-style: normal;
font-weight: bold;
line-height: 30px;
font-size: 20px;
color: #3D3349;
max-width: 200px;
margin-top: 24px;
}
h6{
  font-size: 20px;
}
.text1{
  margin-top: 16px;
  margin-bottom: 24px;
}

.text1,.text2{
  width: 90%;
}

.text2{
  margin-bottom: 32px;
}
.slick-slide.slick-center div {
transform: scale(1.1);
}
.slick-dots li button:before {
  font-size: 20px;
}
.squarePoints{
  display: none;
}
.image{
 width: 500px;
 border-radius: 8px;
 margin-top: 24px;
}
.button-more{
    font-size: 18px;
    background:none;
    border: 1px solid #FF382E;
    border-radius: 4px;
    color: #FF382E;;
    padding: 10px;
    font-weight: bold;
    img{
      margin-left: 16px;
    }
  }
@media (min-width: 576px) { 

} 
`

const settings = {
  dots: true,
  infinite: false,
  speed: 800,
  slidesToScroll: 1,
  initialSlide: 0,
  adaptiveHeight: true,
};