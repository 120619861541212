import React, {Component} from 'react'
import {LanguageContext} from "../Context/LanguageProvider"
import TemplateScreen from "./TemplateScreen"
import getText from "../i18n"
import ContentInsuranceScreen
  from "../components/ContentInsuranceScreen"

class Insurance extends Component {
  static contextType = LanguageContext

  render() {
    const title = getText(this.context.lang, "insurance", "headerTitle")
    return (
      <TemplateScreen
        title={title}
        content={<ContentInsuranceScreen/>}/>
    );
  }
}

export default Insurance;