export default {
  headerTitle: {
    en: 'About',
    fr: 'À propos',
    nl: 'Over'
  },
  title1: {
    en: 'Who is My Self Storage',
    fr: 'À propos de My Self Storage',
    nl: 'Wie is My Self Storage'
  },
  subTitle1: {
    en: 'My Self Storage  is a 3 level building, clean, warmed, on 357 chaussée de Ninove in Anderlecht, 1070 Brussels',
    fr: 'My Self Storage  est  un bâtiment sur 3 étages, propre, chauffé, ventilé , situé  Chaussée de Ninove 357, 1070 Bruxelles :',
    nl: 'My Self Storage is een gebouw op 3 verdiepen, proper, verwarmd, geventileerd, op 357 Ninoofsesteenweg in 1070 Anderlecht :'
  },
  text1: {
    en: [
      'You access to the building with your own individual electronic badge',
      'Inside parking available, under roof and secured.',
      'two big lifts, transpalets and handling material at disposal on site and for free',
      'Each space box is individually cloased. You are the only one to get the keys',
      'As a supplementary security measure, we can propose you individual alarm ssytem on your box (on demand)',
      'Doors with access by individual badge, anti intrusion alarm, CCTV , Fire security, all systems on line with a control room 365/24/7',
      'Our people is at your disposal for any question on 02/647.22.31 e&/or 0479/08.67.56 or by mail info@myselfstorage.biz'
    ],
    fr: [
      'Vous accédez au bâtiments avec votre badge individuel',
      'Parking disponible en sécurité et à l’abri des intempéries à l’intérieur du bâtiment.',
      'deux  grands monte charge , vous avez à disposition des chariots et transpalettes gratuitement ;',
      'Chaque espace de location est  fermé  individuellement , vous êtes  la seule personne en possession des clés de votre box de stockage',
      'Comme mesure de sécurité supplémentaire, My Self Storage  vous propose uniquement sur demande  des locations de stockage avec alarme individuelle',
      'Portes avec accès par badge individuel, alarmes anti-intrusions, caméras de surveillance, sécurité incendie, le tout relié à une société de gardiennage avec patrouille de surveillance et d’intervention sur site 365/24/7',
      'Notre personnel est à votre disposition pour toutes vos questions au 02/647.22.31 et/ou 0479/08.67.56 ou par mail info@myselfstorage.biz',
    ],
    nl: [
      'u heeft toegang via uw eigen badge',
      'beschikbaaar privé parking binnen, onder dak',
      '2 grote liften. We zetten gratis ter beschikking karretjets en palettruck',
      'Elke box is op slot en u bent alleen in beschikking of deze sleutel',
      'Als supplementaire beveilinging optie, My Self Storage stelt u voor boxen met individuele alarm systeem (op aanvraag)',
      'Deuren met individuele badge toegang, anti diefstal alarm systeem, cameras, brand beveiliging met verbinding tot een alarm centrale 365/24/7',
      'Onze personneel is ter uwe beschikking voor alle vragen op 02/647.22.31 en/of 0479/08.67.56 of per mail info@myselfstorage.biz',
    ]
  }
}
