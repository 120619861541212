import React, { Component } from 'react';
import styled from 'styled-components'
import Navbar from "../components/Nav";
import HeaderBoxHome from "./HeaderBoxHome";

const bgImgPath = process.env.PUBLIC_URL + '/assets/img/move.png';

class HeaderHome extends Component {
  render() {
    if(this.props.hide){
      return null
    }//else
    return (
      <Container classsname={'header'}>
        <div className="overlay"/>
        <Navbar />
        <HeaderBoxHome />
      </Container>
    );
  }
}


const Container = styled.div`
height: 50vh;
background: url(${bgImgPath}) center center;
background-size: cover;
min-height: 400px;
position: relative;
.overlay{
background:rgba(0,0,0,0.4);
height: 100%;
width: 100%;
position: absolute;
}
@media (min-width: 992px) { 
height: 85vh;
min-height: 700px;
}
`

export default HeaderHome;
