import React, {Component} from 'react';
import styled from 'styled-components'
import HeaderTemplate
  from "../components/HeaderTemplate";
import getText from "../i18n";
import {LanguageContext} from "../Context/LanguageProvider";
import Footer from "../components/Footer";

class TemplateScreen extends Component {
  static contextType = LanguageContext;

  render() {
    return (
      <Container>
        <HeaderTemplate title={this.props.title}/>
        <div className="about-content container">
          <div className="row">
            <div className="col-md-7">
              {this.props.content}
            </div>
            <div
              className="offset-md-1 col-md-4 info-box">
              <h3>{getText(this.context.lang, 'contact', 'title')}</h3>
              <p>
                {getText(this.context.lang, 'contact', 'lines').map((line, index) => {
                    return (
                      <React.Fragment key={index}>
                        {line}<br/>
                      </React.Fragment>
                    )
                  }
                )}
                <span
                  className={"font-weight-bold"}>{getText(this.context.lang, 'contact', 'bold')}</span>
              </p>
            </div>
          </div>
        </div>
        <Footer/>
      </Container>
    );
  }
}


const Container = styled.div`
.about-content{
padding-top: 64px;
.info-box{
  line-height: 24px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.7);
    h3{
      font-weight: bold;
      line-height: 25px;
      font-size: 18px;
      color: rgba(255, 56, 46, 0.7);
    }
  }
}
`

export default TemplateScreen;
