import React, {Component} from 'react';
import styled from 'styled-components'
import {LanguageContext} from "../Context/LanguageProvider";
import getText from "../i18n/index";
import FormField from './FormField';
import { Redirect } from 'react-router'

import ReCAPTCHA from "react-google-recaptcha";

import Loader from 'react-loader-spinner'

import * as validators from "../validators";
import * as config from '../config'
class ContentContactScreen extends Component {
  static contextType = LanguageContext;

  state = {
    form: {
      floor: false,
      name: {
        value: '',
        error: '',
        validators: [validators.notEmpty],
        sanitizers: [validators.trim],
      },
      firstName: {
        value: '',
        error: '',
        validators: [validators.notEmpty],
        sanitizers: [validators.trim],
      },
      email: {
        value: '',
        error: '',
        validators: [validators.notEmpty, validators.validEmail],
        sanitizers: [validators.trim, validators.emailSanit],
      },
      phone: {
        value: '',
        error: '',
        validators: [validators.notEmpty, validators.validPhone],
        sanitizers: [validators.trim],
      },
      topic: {
        value: '',
        error: '',
        validators: [validators.notEmpty],
        sanitizers: [validators.trim],
      },
      message: {
        value: '',
        error: '',
        validators: [validators.notEmpty],
        sanitizers: [validators.trim],
      },
      captcha: {
        value: '',
        error: '',
        validators: [validators.notEmpty],
        sanitizers: [],
      }
    },
    submit: {
      loading: false,
      success: false,
    }
  }

  async componentDidMount() {
    //ping the server
    await fetch(config.ping)
  }

  async onCaptchaChange(value){
    console.log(value)
    await this.onFieldChange('captcha', value || "", true)
  }

  async editFormState(field, value){
    let newState = {...this.state, form: { ...this.state.form }}
    newState.form[field] = value
    await this.setState(newState) 
  }

  async onFieldChange(fieldName, ev, special) {
    let value = null;
    if(!special){
      ev.preventDefault()
      value = ev.target.value
    }else{
      value = ev
    }
    const {error} = this.validateField(fieldName, value)
    await this.editFormState(fieldName, { ...this.state.form[fieldName], error, value: value })
  }

  submitData(toSubmit) {
    console.log(JSON.stringify(toSubmit))
    this.setState(prev => ({...prev, submit: {...prev.submit, loading: true, success: false}}))
      fetch(config.contactMailUrl, {
        method: 'POST',
        body: JSON.stringify(toSubmit),
        headers: {
          'Content-Type': 'application/json'
        },
      })
      .then((response) => {
        if(response.ok){
          this.setState(prev => ({...prev, submit: {...prev.submit, loading: false, success: true}}))
        } else {
          throw response;
        }
      })
      .catch((err) => {
        this.setState(prev => ({...prev, submit: {...prev.submit, loading: false, success: false}}))
      })
  }

  async onSubmit() {
    let toSubmit = {}
    let errored = false
    for(let fieldName in this.state.form){
      const { newValue, error } = this.validateField(fieldName, this.state.form[fieldName].value)
      if(error){
        await this.editFormState(fieldName, { ...this.state.form[fieldName], error })
        errored = true
      } else {
        toSubmit[fieldName] = newValue
      }
    }
    if(!errored){
      this.submitData(toSubmit)
    }
  }

  validateField(fieldName, value){
    let newValue = value
    //sanitize
    for(let i = 0 ; i < this.state.form[fieldName].sanitizers.length ; ++i){
      newValue = this.state.form[fieldName].sanitizers[i](newValue);
    }
    let error = '';
    //validate
    for(let i = 0 ; i < this.state.form[fieldName].validators.length && !error ; ++i){
      error = this.state.form[fieldName].validators[i](newValue);
    }
    return { newValue, error }
  }

  render() {
    return (
      <Container>
        <div className="section1">
          <h2>{getText(this.context.lang, "contact", "form.title")}</h2>
          <h3>{getText(this.context.lang, "contact", "form.description")}</h3>
          <div className={"formRow"}>
            <FormField label={getText(this.context.lang, 'contact', 'form.fields.name')}
                           value={this.state.form.name.value}
                           placeholder={"Dupont"} 
                           error={this.state.form.name.error}
                           onChange={this.onFieldChange.bind(this, 'name')}/>
            <FormField label={getText(this.context.lang, 'contact', 'form.fields.firstName')}
                           value={this.state.form.firstName.value}
                           placeholder={"Jean"} 
                           error={this.state.form.firstName.error}
                           onChange={this.onFieldChange.bind(this, 'firstName')}/>      
          </div>
          <div className={"formRow"}>
            <FormField label={getText(this.context.lang, 'contact', 'form.fields.email')}
                           value={this.state.form.email.value}
                           placeholder={"jean.dupont@gmail.com"}
                           error={this.state.form.email.error} 
                           onChange={this.onFieldChange.bind(this, 'email')}/>
            <FormField label={getText(this.context.lang, 'contact', 'form.fields.phone')}
                           value={this.state.form.phone.value}
                           placeholder={"047xxxxxxx"} 
                           error={this.state.form.phone.error}
                           onChange={this.onFieldChange.bind(this, 'phone')}/>
          </div>
          <FormField label={getText(this.context.lang, 'contact', 'form.fields.topic')}
                          value={this.state.form.topic.value}
                          placeholder={getText(this.context.lang, 'contact', 'form.fields.topic')} 
                          error={this.state.form.topic.error}
                          onChange={this.onFieldChange.bind(this, 'topic')}/>
          <FormField label={getText(this.context.lang, 'contact', 'form.fields.message')}
                          value={this.state.form.message.value}
                          placeholder={getText(this.context.lang, 'contact', 'form.fields.message')}
                          multiline 
                          error={this.state.form.message.error}
                          onChange={this.onFieldChange.bind(this, 'message')}/>
          <div className={"recaptchaWrapper"}>
            <ReCAPTCHA
              sitekey={config.repcaptchaSiteKey}
              onChange={this.onCaptchaChange.bind(this)}
            />
          </div>
          <p style={{color: 'rgba(255, 0, 0, 0.7)'}}>
            {this.state.form.captcha.error && getText(this.context.lang, "contact", `form.error.${this.state.form.captcha.error}`)}
          </p>
          
          {
            this.state.submit.loading ? (
              <Loader 
                type="Oval"
                color="#FF382E"
                height="50"	
                width="50"
              />   
            ) : (
              <button onClick={this.onSubmit.bind(this)} 
                  className={"button-submit"}>
                    {getText(this.context.lang, 'contact', 'form.submit')}
              </button>
            )
          }
          
          {
            this.state.submit.success && <Redirect to='/'/>
          }

        </div>
      </Container>
    );
  }
}


const Container = styled.div`
  margin-bottom: 15px;
  .section1,.section2{
    display: flex;
    flex-direction: column;
     h2{
      font-weight: 900;
      font-size: 48px;
      color: rgba(255, 56, 46, 0.7);
      margin-bottom: 24px;
    }
    h3{
      font-weight: bold;
      line-height: 21px;
      font-size: 15px;
      color: rgba(0, 0, 0, 0.7);
      margin-bottom: 24px;
    }
   .formRow{
     display: flex;
     flex: 1;
     flex-direction: row;
     flex-wrap: wrap;
   }
   .button-submit{
    font-size: 18px;
    background:none;
    border: 1px solid #FF382E;
    border-radius: 4px;
    color: #FF382E;;
    padding: 10px;
    font-weight: bold;
    margin-top: 32px;
    margin-left: 12px;
    max-width: 200px;
   }
   .recaptchaWrapper{
     margin-left: 15px;
     margin-top: 15px;
     @media screen and (max-height: 575px){
     iframe {
        transform:scale(0.77);
        -webkit-transform:scale(0.77);
        transform-origin:0 0;
        -webkit-transform-origin:0 0;
     }
   }
  }
}
`

export default ContentContactScreen;
