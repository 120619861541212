import React from 'react'
import styled from 'styled-components'
import AboutSectionItemHome
  from "./AboutSectionItemHome";
import {LanguageContext} from "../Context/LanguageProvider";
import getText from "../i18n";
import {Link} from "react-router-dom";

const imgPath = process.env.PUBLIC_URL;
const arrowPath = process.env.PUBLIC_URL + '/assets/img/redArrow.png';
const squarePoints = process.env.PUBLIC_URL + '/assets/img/squarePointStorage.png';

class AboutSectionHome extends React.Component {
  static contextType = LanguageContext;

  render() {
    return (
      <Container
        className={"container-fluid aboutSection"}>
        <div className={"row m-0"}>
          <div
            className={"col-12 col-sm-6 offset-md-1 col-md-5 offset-lg-2 col-lg-4 left"}>
            <header>
              <img src={squarePoints} alt=""/>
              <h2> {getText(this.context.lang, "home", "aboutSection.title")}</h2>
            </header>
            <div className="first-item-wrapper">
              <AboutSectionItemHome
                img={imgPath + 'p&e.png'}
                alt={'particular and professionnal image'}
                title={getText(this.context.lang, "home", "aboutSection.aboutItems.pAndE.title")}
                desc={getText(this.context.lang, "home", "aboutSection.aboutItems.pAndE.text1")}/>
            </div>
            <Link
              className={"button-more"}
              to={'/apropos'}>
              {getText(this.context.lang, "home", "aboutSection.aboutItems.buttonMore")}
              <img src={arrowPath} alt=""/>
            </Link>
          </div>
          <div
            className={"col-12 col-sm-6 col-md-5 col-lg-4"}>
            <AboutSectionItemHome
              img={imgPath + 'box.png'}
              alt={'box image'}
              title={getText(this.context.lang, "home", "aboutSection.aboutItems.space.title")}
              desc={getText(this.context.lang, "home", "aboutSection.aboutItems.space.text1")}/>
            <AboutSectionItemHome
              img={imgPath + 'money.png'}
              alt={'discount image'}
              title={getText(this.context.lang, "home", "aboutSection.aboutItems.price.title")}
              desc={getText(this.context.lang, "home", "aboutSection.aboutItems.price.text1")}/>
          </div>
        </div>
      </Container>
    )
  }
}

export default AboutSectionHome

const Container = styled.div`
  max-width: 1200px;
  margin-top: 32px;
  padding: 16px 0px 0px 16px;
  header{
  position: relative;
  img{
  position: absolute;
  top: -100px;
  left: -50px;
  z-index: -10;
  }
  h2{
    font-weight: 900;
    line-height: normal;
    font-size: 48px;
    text-align: left;
    color :  #FF4238;
    background:white;
  }
  }
  
 .left{
   margin-bottom: 30px;
  .first-item-wrapper{
    margin-bottom: 30px;
 }
 }
  @media (min-width: 768px) { 
    margin-top: 72px;
  }
  .button-more{
    font-size: 18px;
    background:none;
    border: 1px solid #FF382E;
    border-radius: 4px;
    color: #FF382E;;
    padding: 10px;
    font-weight: bold;
    margin-top: 32px;
    margin-left: 12px;
    img{
      margin-left: 16px;
    }
  }
`