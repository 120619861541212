import React, { Component } from 'react'
import styled from 'styled-components'
import {LanguageContext} from "../Context/LanguageProvider";

class LocationPanelHero extends Component {
  
  static contextType = LanguageContext;
  
  render(){
    return (
      <Container>
        <div className={'img'}>
          <img src={this.props.box.pic} alt={"price"} className={'actualPic'} onClick={this.props.onImgClick}/>
        </div>
        <div className={'desc'}>
          <p>{"Box "+this.props.box.area+" m²"}</p>
          <p className={'less'}>{ this.props.box["description"+this.context.lang.toUpperCase()] || "De l'entreposage de vos meubles les plus précieux à la conservation d'archives." }</p>
        </div>
      </Container>
    )
  } 
}

const Container = styled.div`
  margin-left: 15px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  flex: 1;
  max-height: 200px;
  border-radius: 4px;
  border: 2px solid rgba(61, 139, 0, 0.8);
  .img{
    display: flex;
    flex: 1;
    .actualPic{
      max-width: 100%;
      height:auto;
      object-fit: contain;
    }
  }
  .desc {
    display: flex;
    flex: 2;
    height: 150px;
    align-items: flex-start;
    justify-content: space-around;
    flex-direction: column;
    p {
      display: block;
      font-size: 20px;
      font-family: 'Roboto';
    }
    .less {
      color: rgba(0, 0, 0, 0.5)
    }
  }
`

export default LocationPanelHero