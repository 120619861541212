import React, { Component } from 'react';
import {LanguageContext} from "../Context/LanguageProvider";
import TemplateScreen from "./TemplateScreen";
import ContentReservationScreen from "../components/ContentReservationScreen";
import {Lightbox} from 'react-modal-image';

class Partners extends Component {
  static contextType = LanguageContext;

  state = { hideNav: false, modal: null }

  render() {
    return (
      <React.Fragment>
        <TemplateScreen
          title={'Reservation'}
          content={
            <ContentReservationScreen 
              showModal={(uri) => { this.setState({hideNav: true, modal: uri}) }}
              initialBox={this.props.match.params.boxIndex}
              />}
            />
        {
          this.state.modal && 
          <Lightbox large={this.state.modal} 
                    alt={"Full zoom"} 
                    onClose={() => this.setState({hideNav: false, modal: null})}
          />
        }
      </React.Fragment>
    );
  }
}

export default Partners;