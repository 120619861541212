import React from 'react'
import styled from 'styled-components'
import {withRouter} from 'react-router-dom';
import {LanguageContext} from "../Context/LanguageProvider";
import getText from "../i18n";

const logoPath = process.env.PUBLIC_URL + '/assets/img/logo.svg';

class NavTemplate extends React.Component {
  static contextType = LanguageContext;


  render() {
    return (
      <Nav>
        <div className={'logo-wrapper'}>
          <button
            onClick={() => this.props.history.goBack()}>
            {getText(this.context.lang, "template", "nav.back")}
          </button>
          <img className={"logo"} src={logoPath}
               alt="company logo"/>
          <div/>
        </div>
        <ul>
          {this.props.children}
        </ul>
      </Nav>
    )
  }
}

export default withRouter(NavTemplate)

const Nav = styled.nav`
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: space-between;
  z-index: 10;
  .logo-wrapper{
  display: flex;
  margin-top: 24px;
  .logo{
    height: 30px;
 }
 button{
align-self:flex-start;
color: white;
font-weight: 600;
background: rgba(255, 255, 255, 0.1);
border: 1px solid #FFFFFF;
padding: 12px;
margin-top: 10px;
margin-right: 16px;
border-top-right-radius:8px ;
border-bottom-right-radius:8px ;
border-left: none;
text-transform:uppercase;
&:hover{
 cursor: pointer;
}
  }
}
 @media (min-width: 768px) { 
  .logo-wrapper{
  .logo{
    height: 50px;
    margin-top: 10px;
 }
}
`